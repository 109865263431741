var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"elevation":"0"}},[_c('v-tabs',{attrs:{"centered":"","grow":"","active-class":"font-weight-bold tab-active","slider-size":"3"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{class:`tab-header  ${_vm.stClasses.st1}`,attrs:{"href":"#1"}},[_vm._v(" 1 - Datos Generales ")]),_c('v-tab',{class:`tab-header  ${_vm.stClasses.st2}`,attrs:{"href":"#2"}},[_vm._v(" 2 - Documentación ")]),_c('v-tab',{class:`tab-header  ${_vm.stClasses.st3}`,attrs:{"href":"#3"}},[_vm._v(" 3 - Contratación ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"1"}},[_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Número de empleado","valueInput":_vm.values.employe_number,"rule":_vm.rules.employe_number,"errorMessages":_vm.serverErrors.employe_number},on:{"valueChange":(v) => {
                                    _vm.values.employe_number = v.trim();
                                    delete _vm.serverErrors.employe_number;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Nombre completo","valueInput":_vm.values.name,"rule":_vm.rules.name,"errorMessages":_vm.serverErrors.name},on:{"valueChange":(v) => {
                                    _vm.values.name = v.trim();
                                    delete _vm.serverErrors.name;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Correo electrónico","valueInput":_vm.values.email,"rule":_vm.rules.email,"errorMessages":_vm.serverErrors.email},on:{"valueChange":(v) => {
                                    _vm.values.email = v.trim();
                                    _vm.serverErrors.email;
                                    delete _vm.serverErrors.email;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Correo Institucional","valueInput":_vm.values.business_email,"rule":_vm.rules.business_email,"errorMessages":_vm.serverErrors.business_email},on:{"valueChange":(v) => {
                                    _vm.values.business_email = v.trim();
                                    _vm.serverErrors.business_email;
                                    delete _vm.serverErrors.business_email;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Profesión","valueInput":_vm.values.profession,"rule":_vm.rules.required,"errorMessages":_vm.serverErrors.profession},on:{"valueChange":(v) => {
                                    _vm.values.profession = v.trim();
                                    delete _vm.serverErrors.profession;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('autocompleteOneForm',{attrs:{"name":"p1","label":"Lugar de nacimiento","valueInput":_vm.values.birthplace,"rule":_vm.rules.birthplace,"items":_vm.serverItems.nationalities,"errorMessages":_vm.serverErrors.birthplace},on:{"valueChange":(v) => {
                                    _vm.values.birthplace = v.trim();
                                    delete _vm.serverErrors.birthplace;
                                }}})],1),_c('v-col',[_c('datePickerForm',{attrs:{"name":"p1","label":"Fecha de nacimiento","valueInput":_vm.values.birthdate,"rule":_vm.rules.birthdate,"errorMessages":_vm.serverErrors.birthdate},on:{"valueChange":(v) => {
                                    _vm.values.birthdate = v.trim();
                                    delete _vm.serverErrors.birthdate;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('autocompleteOneForm',{attrs:{"name":"p1","label":"Género","valueInput":_vm.values.gender,"rule":_vm.rules.required,"items":_vm.serverItems.genders,"errorMessages":_vm.serverErrors.gender},on:{"valueChange":(v) => {
                                    _vm.values.gender = v;
                                    delete _vm.serverErrors.gender;
                                }}})],1),_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p1","label":"Estado civil","valueInput":_vm.values.marital_status,"rule":_vm.rules.required,"items":_vm.serverItems.marital_statuses,"errorMessages":_vm.serverErrors.marital_status},on:{"valueChange":(v) => {
                                    _vm.values.marital_status = v;
                                    delete _vm.serverErrors.marital_status;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('textFieldForm',{attrs:{"name":"p1","label":"C.U.R.P.","valueInput":_vm.values.curp,"rule":_vm.rules.curp,"errorMessages":_vm.serverErrors.curp},on:{"valueChange":(v) => {
                                    _vm.values.curp = v.trim();
                                    delete _vm.serverErrors.curp;
                                }}})],1),_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"R.F.C.","valueInput":_vm.values.rfc,"rule":_vm.rules.rfc,"errorMessages":_vm.serverErrors.rfc},on:{"valueChange":(v) => {
                                    _vm.values.rfc = v.trim();
                                    delete _vm.serverErrors.rfc;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('textFieldForm',{attrs:{"name":"p1","label":"INFONAVIT","valueInput":_vm.values.infonavit_number,"rule":_vm.rules.infonavit_number,"errorMessages":_vm.serverErrors.infonavit_number},on:{"valueChange":(v) => {
                                    _vm.values.infonavit_number = v.trim();
                                    delete _vm.serverErrors.infonavit_number;
                                }}})],1),_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"FONACOT","valueInput":_vm.values.fonacot_number,"rule":_vm.rules.fonacot_number,"errorMessages":_vm.serverErrors.fonacot_number},on:{"valueChange":(v) => {
                                    _vm.values.fonacot_number = v.trim();
                                    delete _vm.serverErrors.fonacot_number;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('textFieldForm',{attrs:{"name":"p1","label":"N.S.S.","valueInput":_vm.values.nss,"rule":_vm.rules.nss,"errorMessages":_vm.serverErrors.nss},on:{"valueChange":(v) => {
                                    _vm.values.nss = v.trim();
                                    delete _vm.serverErrors.nss;
                                }}})],1),_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Teléfono","valueInput":_vm.values.phone,"rule":_vm.rules.phone,"errorMessages":_vm.serverErrors.phone},on:{"valueChange":(v) => {
                                    _vm.values.phone = v.trim();
                                    delete _vm.serverErrors.phone;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p1","label":"Tipo de sangre","valueInput":_vm.values.blood_type,"rule":[],"items":_vm.serverItems.blood_types,"errorMessages":_vm.serverErrors.blood_type},on:{"valueChange":(v) => {
                                    _vm.values.blood_type = v;
                                    delete _vm.serverErrors.blood_type;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('autocompleteMultipleForm',{attrs:{"name":"p1","label":"Enfermedades Crónicas","valueInput":_vm.values.diseases,"rule":[],"items":_vm.serverItems.diseases,"errorMessages":_vm.serverErrors.diseases},on:{"valueChange":(v) => {
                                    _vm.values.diseases = v;
                                    delete _vm.serverErrors.diseases;
                                }}})],1)],1),(_vm.otherDiseasesComputed)?_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Otras enfermedades","valueInput":_vm.values.other_diseases,"rule":[],"errorMessages":_vm.serverErrors.other_diseases},on:{"valueChange":(v) => {
                                    _vm.values.other_diseases = v.trim();
                                    delete _vm.serverErrors.other_diseases;
                                }}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('location-information',{attrs:{"name":"p1","valuesInput":_vm.values,"rules":_vm.rules,"serverErrors":_vm.serverErrors,"countryLabel":"País de residencia"}})],1)],1),_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Calle","valueInput":_vm.values.street,"rule":_vm.rules.street,"errorMessages":_vm.serverErrors.street},on:{"valueChange":(v) => {
                                    _vm.values.street = v.trim();
                                    delete _vm.serverErrors.street;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('textFieldForm',{attrs:{"name":"p1","label":"No. Exterior","valueInput":_vm.values.ext_number,"rule":_vm.rules.ext_number,"errorMessages":_vm.serverErrors.ext_number},on:{"valueChange":(v) => {
                                    _vm.values.ext_number = v.trim();
                                    delete _vm.serverErrors.ext_number;
                                }}})],1),_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"No. Interior","valueInput":_vm.values.int_number,"rule":[],"errorMessages":_vm.serverErrors.int_number},on:{"valueChange":(v) => {
                                    _vm.values.int_number = v.trim();
                                    delete _vm.serverErrors.int_number;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('textFieldForm',{attrs:{"name":"p1","label":"Contacto de emergencia","valueInput":_vm.values.emergency_contact,"rule":_vm.rules.emergency_contact,"errorMessages":_vm.serverErrors.emergency_contact},on:{"valueChange":(v) => {
                                    _vm.values.emergency_contact = v.trim();
                                    delete _vm.serverErrors.emergency_contact;
                                }}})],1),_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Teléfono de emergencia","valueInput":_vm.values.emergency_number,"rule":_vm.rules.emergency_number,"errorMessages":_vm.serverErrors.emergency_number},on:{"valueChange":(v) => {
                                    _vm.values.emergency_number = v.trim();
                                    delete _vm.serverErrors.emergency_number;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"name":"p1","label":"Nombre completo del beneficiario","valueInput":_vm.values.payee_name,"rule":[],"errorMessages":_vm.serverErrors.payee_name},on:{"valueChange":(v) => {
                                    _vm.values.payee_name = v.trim();
                                    delete _vm.serverErrors.payee_name;
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('textFieldForm',{attrs:{"name":"p1","label":"Teléfono del beneficiario","valueInput":_vm.values.payee_family_phone,"rule":_vm.rules.payee_family_phone,"errorMessages":_vm.serverErrors.payee_family_phone},on:{"valueChange":(v) => {
                                    _vm.values.payee_family_phone = v.trim();
                                    delete _vm.serverErrors.payee_family_phone;
                                }}})],1),_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p1","label":"Parentesco del beneficiario","valueInput":_vm.values.payee_family_relationship,"rule":[],"items":_vm.serverItems.payee_family_relationships,"errorMessages":_vm.serverErrors.payee_family_relationship},on:{"valueChange":(v) => {
                                    _vm.values.payee_family_relationship = v;
                                    delete _vm.serverErrors.payee_family_relationship;
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('fileInputForm',{attrs:{"name":"p1","label":"Fotografía","multiple":false,"valueInput":_vm.values.photo_file,"url":_vm.values.photo_url,"rule":_vm.rules.photo_file,"errorMessages":_vm.serverErrors.photo_file},on:{"valueChange":(v) => {
                                    _vm.values.photo_file = v;
                                    delete _vm.serverErrors.photo_file;
                                },"update:url":function($event){_vm.values.photo_url = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-caption font-weight-black black--text mb-0"},[_vm._v(" Indica si el empleado tiene hijos y da clic en agregar tantas veces como hijos tenga para registrar los datos de cada uno ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('switchForm',{attrs:{"title":"Tiene hijos","leftLabel":"","rightLabel":"Si","valueInput":_vm.values.has_childs,"rule":[]},on:{"valueChange":(v) => {
                                    _vm.values.has_childs = v;
                                }}})],1),(_vm.values.has_childs)?_c('v-col',[_c('secondary-button',{staticClass:"mr-2",attrs:{"props":_vm.addBtnData}})],1):_vm._e()],1),_vm._l((_vm.values.children),function(itemOpt,indexChild){return _c('v-expand-transition',{key:indexChild,attrs:{"appear":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"p-0",attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('autocompleteOneForm',{attrs:{"name":"p1","label":"Género","valueInput":itemOpt.gender,"rule":_vm.rules.required,"items":_vm.serverItems.genders,"errorMessages":_vm.serverErrors[
                                                `children.${indexChild}.option_list.${indexChild}.option`
                                            ]},on:{"valueChange":(v) => {
                                                itemOpt.gender = v;
                                                delete _vm.serverErrors[
                                                    `children.${indexChild}.option_list.${indexChild}.option`
                                                ];
                                            }}})],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('datePickerForm',{attrs:{"name":"p1","label":"Fecha de nacimiento","valueInput":itemOpt.birthdate,"rule":_vm.rules.birthdate,"errorMessages":_vm.serverErrors[
                                                `children.${indexChild}.option_list.${indexChild}.option`
                                            ]},on:{"valueChange":(v) => {
                                                itemOpt.birthdate = v;
                                                delete _vm.serverErrors[
                                                    `children.${indexChild}.option_list.${indexChild}.option`
                                                ];
                                            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"2","align-self":"center"}},[_c('v-row',{staticClass:"mr-0",attrs:{"justify-sm":"end"}},[_c('iconButton',{attrs:{"props":{
                                        color: 'warning',
                                        icon: 'mdi-close',
                                        height: '39',
                                        click: () => {
                                            _vm.removeChild(indexChild);
                                        },
                                    }}})],1)],1)],1)],1)})],2)]),_c('v-tab-item',{attrs:{"value":"2"}},[_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{staticClass:"ml-13"},[_c('span',{staticClass:"body-2"},[_vm._v("Marque la casilla de la izquierda cuando el archivo sea requerido en la documentación del empleado")])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.birthCertificate_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.birthCertificate_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Acta de nacimiento","multiple":false,"valueInput":_vm.values.birthCertificate_file,"url":_vm.values.birthCertificate_url,"rule":_vm.rules.birthCertificate_file,"errorMessages":_vm.serverErrors[
                                        `documentation.ACTA DE NACIMIENTO`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.birthCertificate_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.ACTA DE NACIMIENTO`
                                        ];
                                    },"update:url":function($event){_vm.values.birthCertificate_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.ine_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.ine_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Identificación oficial (INE)","multiple":false,"valueInput":_vm.values.ine_file,"url":_vm.values.ine_url,"rule":_vm.rules.ine_file,"errorMessages":_vm.serverErrors[
                                        `documentation.IDENTIFICACIÓN OFICIAL`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.ine_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.IDENTIFICACIÓN OFICIAL`
                                        ];
                                    },"update:url":function($event){_vm.values.ine_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.curp_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.curp_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"C.U.R.P.","multiple":false,"valueInput":_vm.values.curp_file,"url":_vm.values.curp_url,"rule":_vm.rules.curp_file,"errorMessages":_vm.serverErrors[`documentation.CURP`]},on:{"valueChange":(v) => {
                                        _vm.values.curp_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.CURP`
                                        ];
                                    },"update:url":function($event){_vm.values.curp_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.rfc_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.rfc_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"R.F.C.","multiple":false,"valueInput":_vm.values.rfc_file,"url":_vm.values.rfc_url,"rule":_vm.rules.rfc_file,"errorMessages":_vm.serverErrors[`documentation.RFC`]},on:{"valueChange":(v) => {
                                        _vm.values.rfc_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.RFC`
                                        ];
                                    },"update:url":function($event){_vm.values.rfc_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.nss_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.nss_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"N.S.S.","multiple":false,"valueInput":_vm.values.nss_file,"url":_vm.values.nss_url,"rule":_vm.rules.nss_file,"errorMessages":_vm.serverErrors[`documentation.NSS`]},on:{"valueChange":(v) => {
                                        _vm.values.nss_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.NSS`
                                        ];
                                    },"update:url":function($event){_vm.values.nss_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.proofResidency_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.proofResidency_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Comprobante de domicilio","multiple":false,"valueInput":_vm.values.proofResidency_file,"url":_vm.values.proofResidency_url,"rule":_vm.rules.proofResidency_file,"errorMessages":_vm.serverErrors[
                                        `documentation.COMPROBANTE DE DOMICILIO`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.proofResidency_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.COMPROBANTE DE DOMICILIO`
                                        ];
                                    },"update:url":function($event){_vm.values.proofResidency_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.studyCert_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.studyCert_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Último certificado de estudios","multiple":false,"valueInput":_vm.values.studyCert_file,"url":_vm.values.studyCert_url,"rule":_vm.rules.studyCert_file,"errorMessages":_vm.serverErrors[
                                        `documentation.CERTIFICADO DE ESTUDIOS`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.studyCert_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.CERTIFICADO DE ESTUDIOS`
                                        ];
                                    },"update:url":function($event){_vm.values.studyCert_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.economyStudy_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.economyStudy_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Estudio socioeconómico","multiple":false,"valueInput":_vm.values.economyStudy_file,"url":_vm.values.economyStudy_url,"rule":_vm.rules.economyStudy_file,"errorMessages":_vm.serverErrors[
                                        `documentation.ESTUDIO SOCIOECONÓMICO`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.economyStudy_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.ESTUDIO SOCIOECONÓMICO`
                                        ];
                                    },"update:url":function($event){_vm.values.economyStudy_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.recomendationLetter_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.recomendationLetter_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Carta de recomendación","multiple":false,"valueInput":_vm.values.recomendationLetter_file,"url":_vm.values.recomendationLetter_url,"rule":_vm.rules.recomendationLetter_file,"errorMessages":_vm.serverErrors[
                                        `documentation.CARTA DE RECOMENDACIÓN`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.recomendationLetter_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.CARTA DE RECOMENDACIÓN`
                                        ];
                                    },"update:url":function($event){_vm.values.recomendationLetter_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.psychometricTest_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.psychometricTest_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Pruebas psicométricas","multiple":false,"valueInput":_vm.values.psychometricTest_file,"rule":_vm.rules.psychometricTest_file,"url":_vm.values.psychometricTest_url,"errorMessages":_vm.serverErrors[
                                        `documentation.PRUEBA PSICOMÉTRICA`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.psychometricTest_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.PRUEBA PSICOMÉTRICA`
                                        ];
                                    },"update:url":function($event){_vm.values.psychometricTest_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.internalRules_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.internalRules_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Reglamento interno de trabajo","multiple":false,"valueInput":_vm.values.internalRules_file,"url":_vm.values.internalRules_url,"rule":_vm.rules.internalRules_file,"errorMessages":_vm.serverErrors[
                                        `documentation.REGLAMENTO INTERNO`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.internalRules_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.REGLAMENTO INTERNO`
                                        ];
                                    },"update:url":function($event){_vm.values.internalRules_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.confidential_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.confidential_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Convenio de confidencialidad","multiple":false,"valueInput":_vm.values.confidential_file,"url":_vm.values.confidential_url,"rule":_vm.rules.confidential_file,"errorMessages":_vm.serverErrors[
                                        `documentation.CONVENCIO CONFIDENCIAL`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.confidential_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.CONVENCIO CONFIDENCIAL`
                                        ];
                                    },"update:url":function($event){_vm.values.confidential_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.payment_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.payment_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Propuesta económica","multiple":false,"valueInput":_vm.values.payment_file,"url":_vm.values.payment_url,"rule":_vm.rules.payment_file,"errorMessages":_vm.serverErrors[
                                        `documentation.PROPUESTA ECONÓMICA`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.payment_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.PROPUESTA ECONÓMICA`
                                        ];
                                    },"update:url":function($event){_vm.values.payment_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.ret_infonavit_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.ret_infonavit_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Aviso de retención INFONAVIT","multiple":false,"valueInput":_vm.values.ret_infonavit_file,"url":_vm.values.ret_infonavit_url,"rule":_vm.rules.ret_infonavit_file,"errorMessages":_vm.serverErrors[
                                        `documentation.AVISO DE RETENCIÓN INFONAVIT`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.ret_infonavit_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.AVISO DE RETENCIÓN INFONAVIT`
                                        ];
                                    },"update:url":function($event){_vm.values.ret_infonavit_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.ret_fonacot_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.ret_fonacot_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Aviso de retención FONACOT","multiple":false,"valueInput":_vm.values.ret_fonacot_file,"url":_vm.values.ret_fonacot_url,"rule":_vm.rules.ret_fonacot_file,"errorMessages":_vm.serverErrors[
                                        `documentation.AVISO DE RETENCIÓN FONACOT`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.ret_fonacot_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.AVISO DE RETENCIÓN FONACOT`
                                        ];
                                    },"update:url":function($event){_vm.values.ret_fonacot_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.epp_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.epp_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Vale de equipo de protección personal (opcional)","multiple":false,"valueInput":_vm.values.epp_file,"url":_vm.values.epp_url,"rule":_vm.rules.epp_file,"errorMessages":_vm.serverErrors[
                                        `documentation.VALE DE EQUIPO DE PROTECCIÓN PERSONAL`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.epp_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.VALE DE EQUIPO DE PROTECCIÓN PERSONAL`
                                        ];
                                    },"update:url":function($event){_vm.values.epp_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.waiver_equipment_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.waiver_equipment_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Carta responsiva de equipo (opcional)","multiple":false,"valueInput":_vm.values.waiver_equipment_file,"url":_vm.values.waiver_equipment_url,"rule":_vm.rules.waiver_equipment_file,"errorMessages":_vm.serverErrors[
                                        `documentation.CARTA RESPONSIVA DE EQUIPO`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.waiver_equipment_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.CARTA RESPONSIVA DE EQUIPO`
                                        ];
                                    },"update:url":function($event){_vm.values.waiver_equipment_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.medicalTest_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.medicalTest_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Examen médico (opcional)","multiple":false,"valueInput":_vm.values.medicalTest_file,"url":_vm.values.medicalTest_url,"rule":_vm.rules.medicalTest_file,"errorMessages":_vm.serverErrors[
                                        `documentation.EXAMEN MÉDICO`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.medicalTest_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.EXAMEN MÉDICO`
                                        ];
                                    },"update:url":function($event){_vm.values.medicalTest_url = $event}}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('checkboxForm',{staticClass:"mx-3",attrs:{"label":"","valueInput":_vm.values.letter_required,"rule":[]},on:{"valueChange":(v) => {
                                        _vm.values.letter_required =
                                            v == true ? 1 : 0;
                                    }}}),_c('fileInputForm',{staticClass:"width-available",attrs:{"name":"p2","label":"Carta de no antecedentes penales (opcional)","multiple":false,"valueInput":_vm.values.letter_file,"url":_vm.values.letter_url,"rule":_vm.rules.letter_file,"errorMessages":_vm.serverErrors[
                                        `documentation.ANTECEDENTES PENALES`
                                    ]},on:{"valueChange":(v) => {
                                        _vm.values.letter_file = v;
                                        delete _vm.serverErrors[
                                            `documentation.ANTECEDENTES PENALES`
                                        ];
                                    },"update:url":function($event){_vm.values.letter_url = $event}}})],1)])],1)],1)]),_c('v-tab-item',{attrs:{"value":"3"}},[_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('autocompleteOneForm',{attrs:{"name":"p3","label":"Empresa","valueInput":_vm.values.company,"rule":_vm.rules.company,"items":_vm.serverItems.companies,"loading":_vm.loadingCompanies},on:{"valueChange":(v) => {
                                    _vm.values.company = v;
                                    _vm.getLocations(v);
                                    _vm.getPositions(v);
                                    _vm.values.location = '';
                                }}})],1),_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p3","label":"Locación","valueInput":_vm.values.branch_office_id,"rule":_vm.rules.branch_office_id,"items":_vm.serverItems.locations,"loading":_vm.loadingLocations,"errorMessages":_vm.serverErrors[`contract.branch_office_id`]},on:{"valueChange":(v) => {
                                    _vm.values.branch_office_id = v;
                                    delete _vm.serverErrors[
                                        `contract.branch_office_id`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p3","label":"Departamento","valueInput":_vm.values.department_id,"rule":_vm.rules.required,"items":_vm.serverItems.departments,"loading":_vm.loadingDepartments,"errorMessages":_vm.serverErrors[`contract.department_id`]},on:{"valueChange":(v) => {
                                  _vm.values.department_id = v;
                                  delete _vm.serverErrors[
                                      `contract.department_id`
                                  ];
                              }}})],1)],1),_c('v-row',[_c('v-col',[_c('datePickerForm',{attrs:{"name":"p3","label":"Fecha de ingreso","valueInput":_vm.values.admission_date,"rule":_vm.rules.admission_date,"errorMessages":_vm.serverErrors[`contract.admission_date`]},on:{"valueChange":(v) => {
                                    _vm.values.admission_date = v;
                                    delete _vm.serverErrors[
                                        `contract.admission_date`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p3","label":"Tipo de contrato","valueInput":_vm.values.type,"rule":_vm.rules.type,"items":_vm.serverItems.contactTypes,"errorMessages":_vm.serverErrors[`contract.type`]},on:{"valueChange":(v) => {
                                    _vm.values.type = v;
                                    delete _vm.serverErrors[`contract.type`];
                                }}})],1)],1),(_vm.values.type == 'PERIODO DE PRUEBA')?_c('v-row',[_c('v-col',[_c('datePickerForm',{attrs:{"name":"p3","label":"Fecha de inicio del contrato","valueInput":_vm.values.start_date,"rule":_vm.rules.start_date,"errorMessages":_vm.serverErrors[`contract.start_date`]},on:{"valueChange":(v) => {
                                    _vm.values.start_date = v;
                                    delete _vm.serverErrors[
                                        `contract.start_date`
                                    ];
                                }}})],1),_c('v-col',[_c('datePickerForm',{attrs:{"name":"p3","label":"Fecha de final del contrato","valueInput":_vm.values.end_date,"rule":_vm.rules.end_date,"errorMessages":_vm.serverErrors[`contract.end_date`]},on:{"valueChange":(v) => {
                                    _vm.values.end_date = v;
                                    delete _vm.serverErrors[
                                        `contract.end_date`
                                    ];
                                }}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p3","label":"Modalidad","valueInput":_vm.values.mode,"rule":_vm.rules.mode,"items":_vm.serverItems.modes,"errorMessages":_vm.serverErrors[`contract.mode`]},on:{"valueChange":(v) => {
                                    _vm.values.mode = v;
                                    delete _vm.serverErrors[`contract.mode`];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p3","label":"Puesto","valueInput":_vm.values.position_id,"rule":_vm.rules.position_id,"items":_vm.serverItems.positions,"errorMessages":_vm.serverErrors[`contract.position_id`]},on:{"valueChange":(v) => {
                                    _vm.values.position_id = v;
                                    delete _vm.serverErrors[
                                        `contract.position_id`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('autocompleteOneForm',{attrs:{"name":"p3","label":"Periodicidad de pago","valueInput":_vm.values.payroll_type,"rule":_vm.rules.payroll_type,"items":_vm.serverItems.payroll_types,"errorMessages":_vm.serverErrors[`contract.payroll_type`]},on:{"valueChange":(v) => {
                                    _vm.values.payroll_type = v;
                                    delete _vm.serverErrors[
                                        `contract.payroll_type`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('switchForm',{attrs:{"name":"p3","title":"Requiere apoyo para transporte","leftLabel":"","rightLabel":"Si","valueInput":_vm.values.support_transportation,"rule":_vm.rules.support_transportation,"errorMessages":_vm.serverErrors[
                                    `contract.support_transportation`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.support_transportation = v;
                                    delete _vm.serverErrors[
                                        `support_transportation`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('switchForm',{attrs:{"name":"p3","title":"Debe registrar horario de comida","leftLabel":"","rightLabel":"Si","valueInput":_vm.values.meal_schedule,"rule":_vm.rules.meal_schedule,"errorMessages":_vm.serverErrors[`contract.meal_schedule`]},on:{"valueChange":(v) => {
                                    _vm.values.meal_schedule = v;
                                    delete _vm.serverErrors[
                                        `contract.meal_schedule`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('switchForm',{attrs:{"name":"p3","title":"Recibe compensación por horas extra","leftLabel":"","rightLabel":"Si","valueInput":_vm.values.has_overtime_payment,"rule":[],"errorMessages":_vm.serverErrors[
                                    `contract.has_overtime_payment`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.has_overtime_payment = v;
                                    delete _vm.serverErrors[
                                        `contract.has_overtime_payment`
                                    ];
                                }}})],1)],1),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"text-left col-md-6"},[_c('div',{staticClass:"font-weight-bold body-2"},[_vm._v(" Horarios Laborales ")])])],1),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"my-0 py-0"},[_c('checkboxForm',{attrs:{"name":"p3","label":"Repetir de lunes a viernes","valueInput":_vm.values.repeat_schedule,"rule":_vm.rules.repeat_schedule,"errorMessages":_vm.serverErrors[`contract.repeat_schedule`]},on:{"valueChange":(v) => {
                                    _vm.values.repeat_schedule = v;
                                    delete _vm.serverErrors[
                                        `contract.repeat_schedule`
                                    ];
                                    this.checkRepeat();
                                }}})],1)],1),_c('v-row',{staticClass:"less-margin mt-5 pt-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario lunes entrada","valueInput":_vm.values.monday_schedule_start,"rule":_vm.rules.monday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.monday_schedule_start`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.monday_schedule_start = v;
                                    delete _vm.serverErrors[
                                        `contract.monday_schedule_start`
                                    ];
                                    this.checkRepeat();
                                }}})],1),_c('v-col',[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario lunes salida","valueInput":_vm.values.monday_schedule_end,"rule":_vm.rules.monday_schedule,"errorMessages":_vm.serverErrors[`contract.monday_schedule_end`]},on:{"valueChange":(v) => {
                                    _vm.values.monday_schedule_end = v;
                                    delete _vm.serverErrors[
                                        `contract.monday_schedule_end`
                                    ];
                                    this.checkRepeat();
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario martes entrada","valueInput":_vm.values.tuesday_schedule_start,"rule":_vm.rules.tuesday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.tuesday_schedule_start`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.tuesday_schedule_start = v;
                                    delete _vm.serverErrors[
                                        `contract.tuesday_schedule_start`
                                    ];
                                }}})],1),_c('v-col',[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario martes salida","valueInput":_vm.values.tuesday_schedule_end,"rule":_vm.rules.tuesday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.tuesday_schedule_end`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.tuesday_schedule_end = v;
                                    delete _vm.serverErrors[
                                        `contract.tuesday_schedule_end`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario miércoles entrada","valueInput":_vm.values.wednesday_schedule_start,"rule":_vm.rules.wednesday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.wednesday_schedule_start`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.wednesday_schedule_start = v;
                                    delete _vm.serverErrors[
                                        `contract.wednesday_schedule_start`
                                    ];
                                }}})],1),_c('v-col',[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario miércoles salida","valueInput":_vm.values.wednesday_schedule_end,"rule":_vm.rules.wednesday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.wednesday_schedule_end`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.wednesday_schedule_end = v;
                                    delete _vm.serverErrors[
                                        `contract.wednesday_schedule_end`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario jueves entrada","valueInput":_vm.values.thursday_schedule_start,"rule":_vm.rules.thursday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.thursday_schedule_start`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.thursday_schedule_start = v;
                                    delete _vm.serverErrors[
                                        `contract.thursday_schedule_start`
                                    ];
                                }}})],1),_c('v-col',[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario jueves salida","valueInput":_vm.values.thursday_schedule_end,"rule":_vm.rules.thursday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.thursday_schedule_end`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.thursday_schedule_end = v;
                                    delete _vm.serverErrors[
                                        `contract.thursday_schedule_end`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario viernes entrada","valueInput":_vm.values.friday_schedule_start,"rule":_vm.rules.friday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.friday_schedule_start`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.friday_schedule_start = v;
                                    delete _vm.serverErrors[
                                        `contract.friday_schedule_start`
                                    ];
                                }}})],1),_c('v-col',[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario viernes salida","valueInput":_vm.values.friday_schedule_end,"rule":_vm.rules.friday_schedule,"errorMessages":_vm.serverErrors[`contract.friday_schedule_end`]},on:{"valueChange":(v) => {
                                    _vm.values.friday_schedule_end = v;
                                    delete _vm.serverErrors[
                                        `contract.friday_schedule_end`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario sábado entrada","valueInput":_vm.values.saturday_schedule_start,"rule":_vm.rules.saturday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.saturday_schedule_start`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.saturday_schedule_start = v;
                                    delete _vm.serverErrors[
                                        `contract.saturday_schedule_start`
                                    ];
                                }}})],1),_c('v-col',[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario sábado salida","valueInput":_vm.values.saturday_schedule_end,"rule":_vm.rules.saturday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.saturday_schedule_end`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.saturday_schedule_end = v;
                                    delete _vm.serverErrors[
                                        `contract.saturday_schedule_end`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario domingo entrada","valueInput":_vm.values.sunday_schedule_start,"rule":_vm.rules.sunday_schedule,"errorMessages":_vm.serverErrors[
                                    `contract.sunday_schedule_start`
                                ]},on:{"valueChange":(v) => {
                                    _vm.values.sunday_schedule_start = v;
                                    delete _vm.serverErrors[
                                        `contract.sunday_schedule_start`
                                    ];
                                }}})],1),_c('v-col',[_c('timePickerForm',{attrs:{"name":"p3","label":"Horario domingo salida","valueInput":_vm.values.sunday_schedule_end,"rule":_vm.rules.sunday_schedule,"errorMessages":_vm.serverErrors[`contract.sunday_schedule_end`]},on:{"valueChange":(v) => {
                                    _vm.values.sunday_schedule_end = v;
                                    delete _vm.serverErrors[
                                        `contract.sunday_schedule_end`
                                    ];
                                }}})],1)],1),_c('v-row',[_c('v-col',[_c('fileInputForm',{attrs:{"name":"p3","label":"PDF de contratos","multiple":true,"valueInput":_vm.values.contract_file,"url":_vm.values.contract_path,"rule":[],"errorMessages":_vm.serverErrors[`contract.contract_file`]},on:{"valueChange":(v) => {
                                    _vm.values.contract_file = v;
                                    delete _vm.serverErrors[
                                        `contract.contract_file`
                                    ];
                                },"update:url":function($event){_vm.values.contract_path = $event}}})],1)],1),_vm._l((_vm.values.contract_multifiles),function(item,index){return _c('v-row',{key:index},[_c('v-col',[_c('FilePrevFormVue',{attrs:{"nameImg":item.name,"url":item.url},on:{"update:url":function($event){item.url = $event;
                                _vm.removeFile(index);}}})],1)],1)})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }