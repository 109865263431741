var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"mx-0 px-0 py-0",attrs:{"cols":"12","md":"2","lg":"2","xl":"3"}},[_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('v-col',{},[_c('AlternativeAutocompleteOne',{attrs:{"label":'Empresa',"items":_vm.serverItems.companies,"valueInput":_vm.selectedCompany,"loading":_vm.loadingCompanies,"sortAsc":true},on:{"valueChange":(v) => {_vm.selectedCompany = v; _vm.index()}}})],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","xl":"5"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"mx-0 mr-md-3 mr-lg-0 mr-xl-0 px-1 pl-1 width-fit text-right",attrs:{"cols":"1"}},[_c('iconButton',{attrs:{"props":{
                                    color:'primary',
                                    icon:'mdi-magnify',
                                    
                                    height:'35',
                                    click: () => {_vm.openModalSearch()}
                                    }}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-0 px-0"},[_c('datatable',{staticClass:"table-employess",attrs:{"props":_vm.tableProps},on:{"method_handler":_vm.method_handler}})],1)],1)],1)],1),_c('modal',{attrs:{"props":_vm.modalSearchProps},on:{"modalResponse":_vm.method_handler}},[_c('template',{slot:"cardBody"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"pr-0"},[_c('textFieldForm',{ref:"searchInput",attrs:{"label":"Nombre del empleado","valueInput":_vm.searchName,"rule":[]},on:{"valueChange":(v) => {_vm.searchName = v.trim(); },"enterPressed":() => {this.search()}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('primaryButton',{attrs:{"props":_vm.searchModalBtnData}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-0 px-0"},[_c('datatable',{attrs:{"props":_vm.tableSearchProps},on:{"method_handler":_vm.method_handler}})],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }