<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'users.create'" cols="12" md="3" lg="2" class="mx-0 px-0 text-right">
                        <iconButton :props="createBtnData"></iconButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <!-- PASSWORD CHANGE MODAL -->
        <modal :props="modalPasswordProps" @modalResponse="method_handler">
            <!-- FORM -->
            <template slot="cardBody">
				<v-form ref="form" @submit.prevent="save">
                    <v-row>
                        <v-col class="py-2">
                            <textFieldForm 
                                @valueChange="(v) => {password = v; delete serverErrors.password}" 
                                :valueInput="password" 
                                :rule="[...rules.required, ...rules.length8]" 
                                label="Contraseña"
                                :errorMessages="serverErrors.password"
                                :count="true"
                                :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPass ? 'text' : 'password'"
                                @clickAppend="() => {showPass = !showPass}"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-2">
                            <textFieldForm 
                                @valueChange="(v) => {password_confirmation = v; delete serverErrors.password_confirmation}" 
                                :valueInput="password_confirmation" 
                                :rule="[...rules.required, ...rules.samePassword, ...rules.length8]" 
                                label="Confirmar contraseña"
                                :errorMessages="serverErrors.password_confirmation"
                                :count="true"
                                :type="showPass ? 'text' : 'password'"
                                @click:append="showPass = !showPass"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                </v-form>
			</template>
            <!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelPasswordModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptPasswordModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

		<!-- RESEND VERIFICATION EMAIL -->
        <modal :props="modalSendVerificationEmailProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
				
                <v-row class="mt-1">
                    <v-col class="px-0">
                        
                        <p class="pb-0">
                            ¿DESEAS REENVIAR EL CORREO DE VERIFICACIÓN DE CUENTA PARA ESTE USUARIO?
                        </p>

                    </v-col>
                </v-row>
                
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-col class="py-0">
                    <v-row justify="center" >
                        <v-col cols="12" sm="4" class="">
                            <secondaryButton
                                :props="cancelSendVerificationEmailModal"
                            ></secondaryButton>
                        </v-col>
                        
                        <v-col cols="12" sm="4"  class="mr-sm-2">
                            <primaryButton
                            :props="acceptSendVerificationEmailModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </v-col>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import Utils from '../../../../helpers/Utils'
export default {
    data(){
		return {
            password: '',
            password_confirmation: '',
            showPass: false,
            serverErrors:{},
            selectedID: '',
            search: '',
			actions: [
				
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'users.update'
				},
                {
					name: 'Cambiar contraseña',
					icon: {
						color: 'secondary',
						icon: 'mdi-lock-reset'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'changePasswordModal',
                    permissions: 'users.update',
				},
				{
					name: 'Reenviar correo de verificación',
					icon: {
						color: 'secondary',
						icon: 'mdi-email-sync-outline'
					},
					disabled: true,
					type: 'method',   //  method, external, redirect
					action: 'sendVerificationEmailModal',
                    permissions: 'users.update',
				},
				// {
				// 	name: 'Borrar',
				// 	icon: {
				// 		color: 'secondary',
				// 		icon: 'mdi-delete-outline'
				// 	},
				// 	disabled: false,
				// 	type: 'method',   //  method, external, redirect
				// 	action: 'deleteItemModal'
				// },
			],
			tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						
						class: 'table-b-border'
					},
                    {
						text: 'Correo',
						align: 'left',
						value: 'email',
						class: 'table-b-border'
					},
                    {
						text: 'Rol',
						align: 'left',
						value: 'role',
						class: 'table-b-border'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
						class: 'table-b-border'
					},
					{
						text: 'Verificado',
						align: 'left',
						value: 'email_verified_at',
						class: 'table-b-border'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                samePassword:[
                    (v) => v == this.password || "Las contraseñas no coinciden",
                ],
                length8:[
                    v => !!v || 'El campo requerido',
                    v => (!!v && v.length >= 8) || 'Al menos de 8 caracteres'
                ]
				
				
            },
			
			
            createBtnData: {
				hasTooltip:true,
                tooltip: 'Nuevo usuario',
                color:'primary',
                height:'35',
                text: "",
                icon: "mdi-plus",
				to: "UsersCreate",
				block: true,
                click: ()=>{}
            },

            /**
             * Delete modal
             */
			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: false,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                
                icon: "",
				to: "",
				block: false,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.delete()}
            },

            /**
             * Password Modal
             */
            modalPasswordProps:{
                visible: false,
				width: '500',
				title: 'Cambio de contraseña',
				text: '',
				loading: false,
				bottomLineColor: '',
                persistent:true,
            },

            cancelPasswordModal: {
                text: "Cancelar",
                classes: "center-justify",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalPasswordProps.visible = false; this.resetPassForm()}
            },
            acceptPasswordModal: {
                classes: "center-justify",
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				loading:false,
                click: ()=>{this.changePassword()}
            },

			/**
             * Resend verification email Modal
             */
			modalSendVerificationEmailProps:{
                visible: false,
				width: '500',
				title: 'Reenviar correo de verificación',
				text: '',
				loading: false,
				bottomLineColor: '',
                persistent:true,
            },

            cancelSendVerificationEmailModal: {
                text: "No, cancelar",
                classes: "center-justify",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalSendVerificationEmailProps.visible = false}
            },
            acceptSendVerificationEmailModal: {
                classes: "center-justify",
                text: "Si, enviar",
                icon: "",
				to: "",
				block: true,
				loading:false,
                click: ()=>{this.sendVerificationEmail()}
            },
		}
	},

	mounted(){
		this.index()
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;
			
			//call api
			this.$api.user.index()
				.then((resp) =>{
					//console.log(resp, "desde areas");
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						x.actions[0].action = { name: 'UsersEdit', params: { id: x.id } }

						// x.actions[2].action = { params: { id: x.id }, disabled: x.email_verified_at ? true: false }
						// x.actions[2].action = { params: { id: x.id }, disabled: false }
						x.actions[2].disabled = x.email_verified_at ? true: false;
						// x.actions[1].action = { name: 'UsersEdit', params: { id: x.id } }
						// x.actions[1].action = { name: '', params: { id: x.id } }
						

						x['parameters'] = { id: x.id, name: x.name}
						x.status = x.status == true ? 'Activo' : 'Inactivo';
						x.email_verified_at = x.email_verified_at ? 'Si' : 'No';
                        x.name = x.employee.name;
                        x.role = x.role.name;
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error desde areas");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        resetPassForm()
        {
            this.password = this.password_confirmation = '';
            this.$refs.form.reset();
        },
        changePasswordModal(parameters)
        {
            this.selectedID = parameters.id;
			this.modalPasswordProps = {
				...this.modalPasswordProps,
			}
            console.log(this.selectedID)
			this.modalPasswordProps.visible = true;
        },

        changePassword()
        {   
            if(this.validate())
            {
                this.modalPasswordProps.loading = true;
                this.$api.user.changePassword({'password':this.password, 'password_confirmation': this.password_confirmation}, this.selectedID)
                    .then(() => {
                        this.$store.dispatch('snackbarSuccess', `Se ha cambiado la contraseña`);
                        this.resetPassForm();
                        this.modalPasswordProps.visible = false;
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                    })
                    .finally(() => {
                        this.modalPasswordProps.loading = false;
                        
                    })
                ;
            }
        },

		sendVerificationEmailModal(parameters)
        {
            this.selectedID = parameters.id;
			this.modalSendVerificationEmailProps = {
				...this.modalSendVerificationEmailProps,
			}
            console.log(this.selectedID)
			this.modalSendVerificationEmailProps.visible = true;
        },

		sendVerificationEmail()
        {   
                this.modalSendVerificationEmailProps.loading = true;
                this.$api.user.sendEmailVerification(this.selectedID)
                    .then(() => {
                        this.$store.dispatch('snackbarSuccess', `Se ha enviado el correo de verificación`);
                        this.modalSendVerificationEmailProps.visible = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.modalSendVerificationEmailProps.loading = false;
                    })
                ;
        },

    }
}
</script>

<style lang="scss">
   
</style>