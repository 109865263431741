<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center" justify="space-between">
					<v-col cols="12" md="2" lg="2" xl="3" class="mx-0 px-0 py-0">
						<v-row class="" justify="start" no-gutters>
						
							<v-col class="">
								<AlternativeAutocompleteOne
                                    :label="'Empresa'"
									:items="serverItems.companies"
                                    :valueInput="selectedCompany"
                                    :loading="loadingCompanies"
									@valueChange="(v) => {selectedCompany = v; index()}"
                                    :sortAsc="true"
								></AlternativeAutocompleteOne>
							</v-col>
						</v-row>
					</v-col>
                    
					<v-spacer></v-spacer>
                    <v-col cols="12" lg="6" md="6" xl="5">
                        <v-row justify="end">
                            
                            
                            <v-col cols="1" class="mx-0 mr-md-3 mr-lg-0 mr-xl-0 px-1 pl-1 width-fit text-right">
                                <iconButton
                                    :props="{
                                    color:'primary',
                                    icon:'mdi-magnify',
                                    
                                    height:'35',
                                    click: () => {openModalSearch()}
                                    }"
                                ></iconButton>
                            </v-col>
                         
                        </v-row>
                    </v-col>
                   
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler" class="table-employess">
						
						</datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <!-- SEARCH -->
        <modal :props="modalSearchProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
				
                <v-row class="mt-1">
                    <v-col class=" pr-0">
                        <textFieldForm
                            ref="searchInput"
                            label="Nombre del empleado"
                            :valueInput="searchName"
                            :rule="[]"
                            @valueChange="(v) => {searchName = v.trim(); }"
                            @enterPressed="() => {this.search()}"
                        ></textFieldForm>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <primaryButton
						:props="searchModalBtnData"
						></primaryButton>
                    </v-col>

                </v-row>

                <v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableSearchProps" @method_handler="method_handler">
							
						</datatable>
					</v-col>
				</v-row>
                    
			</template>
			<!-- Buttons -->
			
		</modal>
      
     
	</div>
</template>
<script>
// import ChipDataTableItem from "../../../components/ChipDataTableItem.vue";
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
	components: {
    //    'chip-item': ChipDataTableItem
    },
    data(){
		return {
            searchName: "",
            selectedCompany: PageStateManager.getState(this.$router.history.current.path, 'selectedCompany')??'',
			loadingCompanies: false,
			actions: [
                {
					name: 'Estadísticas',
					icon: {
						color: 'secondary',
						icon: 'mdi-chart-box-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/estadisticas',
                    permissions: 'integradora_user.show'
				},
                
			],
			tableProps: {
				headers: [
                    
				],
				items: [],
				search: '',
				loading: false
			},

            tableSearchProps:{
                headers: [
                    {
						text: 'Empleado',
						align: 'left small-text-8',
						value: 'name',
						class: 'table-b-border '
					},
					{
						text: 'Empresa',
						align: 'left small-text-8',
						value: 'company',
						class: 'table-b-border small-text-8'
					},
                    {
						text: 'Sucursal',
						align: 'left small-text-8',
						value: 'branch',
						class: 'table-b-border'
					},
                    
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                itemsPerPageStart: 5,
                
            },
			
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			},
            searchModalBtnData: {
                text: "Buscar",
                icon: "",
				to: "",
				block: true,
                click: ()=>{this.search()}
            },
            

            serverItems: {
                companies: [{text: "Empresa | Grupo Merca", value: "merca"}],
                offices: [],
                exportBusinessList: [],
            },

            modalProps: {
				visible: false,
				width: '500',
				title: 'Compartir el cuestionario para actualización de datos',
				text: 'Comparte este enlace con Dulce Ulibarri para invitarle a actualizar su información personal, este enlace tendrá una vigencia de 48 horas',
				loading: false,
				bottomLineColor: 'success',
				
			},
            
            
			acceptModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'success',
				textColor:'white',
                textColorLevel: "5",
                classes: "justi-center",
				loading:false,
                click: ()=>{this.modalProps.visible = false}
            },

         
            modalSearchProps: {
                visible: false,
				width: '800',
				title: 'Buscar Empleado',
				text: '',
				loading: false,
                hasCloseBtn: true,
				bottomLineColor: 'primary',
            },

         
			
		}
	},
    watch: {
        /**
         * Reset values on "type" change
         */
        [`selectedCompany`]: function () {
            console.log(this.selectedCompany)
            PageStateManager.setState(this.$router.history.current.path, 'selectedCompany', this.selectedCompany)
        },
       
       
       
    },
    computed: {
       
    },

	mounted(){
        
        if(this.$gates.hasPermission('area_rol_user.show')){
            this.$router.push({name: 'EmployeesArea'});
        }else{
            this.initTable();
            this.getCompanies();

        }
		//this.index()
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
       
        initTable(){
           
            this.tableProps.headers = [
                {
                    text: 'No.\n Empleado',
                    align: 'left small-th-employees',
                    value: 'employe_number',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Nombre',
                    align: 'left small-th-employees',
                    value: 'name',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Correo',
                    align: 'center small-th-employees',
                    value: 'email',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Teléfono',
                    align: 'center small-th-employees',
                    value: 'phone',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Puesto',
                    align: 'center small-th-employees',
                    value: 'position',
                    class: 'table-b-border white-space-normal'
                },
                {
                    text: 'Sucursal',
                    align: 'center small-th-employees',
                    value: 'office',
                    class: 'table-b-border white-space-normal'
                },
                {
                    text: 'Fecha\ningreso',
                    align: 'left small-th-employees',
                    value: 'date_i',
                    class: 'table-b-border white-space-normal'
                },
               
                {
                    text: 'Acciones',
                    align: 'right',
                    sortable: false,
                    value: 'actions',
                    class: 'table-b-border'
                }
            ]
        },
     
		index() {
			this.tableProps.loading = true;
			//call api
			this.$api.externalUser.employeesIndex(this.selectedCompany)
				.then((resp) =>{
					console.log(resp, "employees index");
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))

						x.actions[0].action = { name: 'EEmployeesStats', params: { id: x.id } }
						
						x['parameters'] = { id: x.id, name: x.name};
						x.position = x.current_contract?.position?.name??'';
						x.date_i = Utils.globalDateFormat(x.current_contract?.admission_date);
                        x.office = x.current_contract?.branch_office?.name??'';

						
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error employees index");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
		},

        setBusinessFirst(){
            if(this.serverItems.companies.length >= 1){
                if(this.selectedCompany == "")
                    this.selectedCompany = this.serverItems.companies[0].value;
                this.index();
            }
        },
		getCompanies()
        {
            this.serverItems.companies = [];
            if(this.$store.state.externalBusiness.length > 0){
                this.serverItems.companies = this.$store.state.externalBusiness;
                this.setBusinessFirst();
                return;
            }
            this.loadingCompanies = true;
            this.$flujoApi.business.getBusinessSelect()
				.then((resp) =>{
                    
                    //index filter
					this.serverItems.companies = resp.data;
                    this.$store.state.externalBusiness = resp.data;

                    this.setBusinessFirst();

                    //export filter
                    this.serverItems.exportBusinessList.push({value: "ALL", text: "TODAS"});
                    this.serverItems.exportBusinessList = resp.data;
					
				})
				.catch((error) =>{
					console.log(error, "error getBusinessSelect");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        },

        openModalSearch()
        {
            this.tableSearchProps.itemsPerPageStart = 5;
            this.tableSearchProps.items = [];
            this.searchName = "";
            this.modalSearchProps.visible = true;
            setTimeout(() => {
                this.$refs.searchInput.$children[0].focus()
            });
            
        },

        search()
        {
            if(this.searchName.trim() == "")
                return;
            
            this.tableSearchProps.loading = true;
            
            this.$api.externalUser.searchEmployees(this.searchName)
				.then((resp) =>{
					this.tableSearchProps.items = resp.data.map((x) => {
						let acts = JSON.parse(JSON.stringify(this.actions))
                        x['actions'] = acts;
						x.actions[0].action = { name: 'EEmployeesStats', params: { id: x.id } }
						
						
						
						x['parameters'] = { id: x.id, name: x.name};

                        x.company = x.current_contract?.branch_office?.company?.name??"";
                        x.branch = x.current_contract?.branch_office.name??"";
						
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error employees index");
				})
				.finally(() => {
                    this.tableSearchProps.loading = false;
				})
			;
        },

     

    }
}
</script>

<style lang="scss">

    .container-input-share{
        border: 1px solid gray;
    }

    .outside{
        margin-top: -7px !important;
    }

    .justi-center{
        justify-content: center;
    }

    .width-fit{
        max-width: fit-content;
    
    }

    .chip-employees-index{
        min-width: 32px;
        justify-content: center;
    }

  
    
    // @media (max-width: $display-breakpoints-xl)  {
    //     .width-fit{
    //         max-width: fit-content !important;
    
    //     }
    // }

    // @media (max-width: $display-breakpoints-sm)  {
    //     .width-fit{
    //         max-width: unset;
    
    //     }
    // }


    
</style>