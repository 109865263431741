<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<!-- <v-col cols="12" md="3" lg="3"  class="mx-0 px-0">
						
					</v-col> -->
					<v-spacer></v-spacer>
					<v-col v-permission="'surveys.create'" cols="12" md="3" lg="3"  class="mx-0 px-0 text-right">
                        <iconButton :props="createBtnData"></iconButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col  class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler">
							<!-- chip status item -->
							<template v-slot:[`item.status`]="{ item }">
								<chip-item :color="getColorStatus(item.status)" :text="item.status"></chip-item>
							</template> 
							<!--  -->
						</datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

		
	</div>
</template>
<script>
import ChipDataTableItem from "../../../../components/ChipDataTableItem.vue";
export default {
	components: {
       'chip-item': ChipDataTableItem
    },
    data(){
		return {
			selectedID: '',
			actions: [
                {
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'surveys.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/vacantes/1/edicion',
                    permissions: 'surveys.update'
				},
              
				{
					name: 'Compartir',
					icon: {
						color: 'secondary',
						icon: 'mdi-share-variant-outline'
					},
					disabled: true,
					type: 'method',   //  method, external, redirect
					action: 'openShareModal',
                    permissions: 'surveys.create'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Clave',
						align: 'left',
						value: 'id',
						class: 'table-b-border'
					},
                    {
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Fecha de creación',
						align: 'left',
						value: 'date_c',
						class: 'table-b-border'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
						class: 'table-b-border'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Compartir el cuestionario',
				text: 'Al compartir el cuestionario, este se enviará a todos los empleados adscritos a los registros patronales señalados. \n ¿Estás seguro de continuar?',
				loading: false,
				bottomLineColor: 'success',
				
			},
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			},
			cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: false,
				color: 'success',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "si, continuar",
                icon: "",
				to: "",
				block: false,
				color: 'success',
				textColor:'white',
				loading:false,
                click: ()=>{this.share()}
            },
            createBtnData: {
				hasTooltip:true,
                tooltip: 'Nuevo cuestionario',
                color:'primary',
                height:'35',
                text: "",
                icon: "mdi-plus",
				to: "QuestionaryCreate",
				block: true,
                click: ()=>{}
            }
		}
	},

	mounted(){
		this.index()
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.tableProps.loading = true;
			//call api
			this.$api.questionaries.index()
				.then((resp) =>{
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						x.actions[0].action = { name: '', params: { id: x.id } }
						x.actions[1].action = { name: 'QuestionaryEdit', params: { id: x.id } }
						//x.actions[2].action = { name: '', params: { id: x.id } }
						
						x.date_c = new Date(x.created_at).toLocaleDateString('es');
						x['parameters'] = { id: x.id, name: x.name}
						x.status = x.status.with_answers >= x.status.total ? 'Completo':'Abierta';
						return x
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
		},


		openShareModal(parameters)
		{
			this.selectedID = parameters.id;
			this.modalProps = {
				visible: true,
				width: '500',
				title: 'Compartir el cuestionario',
				text: 'Al compartir el cuestionario, este se enviará a todos los empleados adscritos a los registros patronales señalados. \n ¿Estás seguro de continuar?',
				loading: false,
				bottomLineColor: 'success',
				
			}
			this.modalProps.visible = true;
			
		},


		share()
		{
			console.log(this.selectedID);
			this.acceptModal.loading = true;
		},

        getColorStatus (status) {
            if(status == "Completo")
                return 'success';
            if(status == "Abierta")
				return 'caution';
			if(status == "Cerrada")
				return 'warning';
        },

    }
}
</script>