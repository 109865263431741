<template #default="{ errorMessages }">
    <div elevation="0">
        <v-tabs
            v-model="tab"
            centered
            grow
            active-class="font-weight-bold tab-active"
            slider-size="3"
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#1" :class="`tab-header  ${stClasses.st1}`">
                1 - Datos Generales
            </v-tab>

            <v-tab href="#2" :class="`tab-header  ${stClasses.st2}`">
                2 - Documentación
            </v-tab>

            <v-tab href="#3" :class="`tab-header  ${stClasses.st3}`">
                3 - Contratación
            </v-tab>
        </v-tabs>

        <!-- STEP 1 "Datos generales" -->
        <v-tabs-items v-model="tab">
            <v-tab-item value="1">
                <div class="mt-3">
                    <v-row>
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Número de empleado"
                                :valueInput="values.employe_number"
                                :rule="rules.employe_number"
                                @valueChange="
                                    (v) => {
                                        values.employe_number = v.trim();
                                        delete serverErrors.employe_number;
                                    }
                                "
                                :errorMessages="serverErrors.employe_number"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Nombre completo"
                                :valueInput="values.name"
                                :rule="rules.name"
                                @valueChange="
                                    (v) => {
                                        values.name = v.trim();
                                        delete serverErrors.name;
                                    }
                                "
                                :errorMessages="serverErrors.name"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Correo electrónico"
                                :valueInput="values.email"
                                :rule="rules.email"
                                @valueChange="
                                    (v) => {
                                        values.email = v.trim();
                                        serverErrors.email;
                                        delete serverErrors.email;
                                    }
                                "
                                :errorMessages="serverErrors.email"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Correo Institucional"
                                :valueInput="values.business_email"
                                :rule="rules.business_email"
                                @valueChange="
                                    (v) => {
                                        values.business_email = v.trim();
                                        serverErrors.business_email;
                                        delete serverErrors.business_email;
                                    }
                                "
                                :errorMessages="serverErrors.business_email"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Profesión"
                                :valueInput="values.profession"
                                :rule="rules.required"
                                @valueChange="
                                    (v) => {
                                        values.profession = v.trim();
                                        delete serverErrors.profession;
                                    }
                                "
                                :errorMessages="serverErrors.profession"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <autocompleteOneForm
                                name="p1"
                                label="Lugar de nacimiento"
                                :valueInput="values.birthplace"
                                :rule="rules.birthplace"
                                :items="serverItems.nationalities"
                                @valueChange="
                                    (v) => {
                                        values.birthplace = v.trim();
                                        delete serverErrors.birthplace;
                                    }
                                "
                                :errorMessages="serverErrors.birthplace"
                            ></autocompleteOneForm>
                        </v-col>

                        <v-col>
                            <datePickerForm
                                name="p1"
                                label="Fecha de nacimiento"
                                :valueInput="values.birthdate"
                                :rule="rules.birthdate"
                                @valueChange="
                                    (v) => {
                                        values.birthdate = v.trim();
                                        delete serverErrors.birthdate;
                                    }
                                "
                                :errorMessages="serverErrors.birthdate"
                            ></datePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <autocompleteOneForm
                                name="p1"
                                label="Género"
                                :valueInput="values.gender"
                                :rule="rules.required"
                                :items="serverItems.genders"
                                @valueChange="
                                    (v) => {
                                        values.gender = v;
                                        delete serverErrors.gender;
                                    }
                                "
                                :errorMessages="serverErrors.gender"
                            ></autocompleteOneForm>
                        </v-col>

                        <v-col>
                            <autocompleteOneForm
                                name="p1"
                                label="Estado civil"
                                :valueInput="values.marital_status"
                                :rule="rules.required"
                                :items="serverItems.marital_statuses"
                                @valueChange="
                                    (v) => {
                                        values.marital_status = v;
                                        delete serverErrors.marital_status;
                                    }
                                "
                                :errorMessages="serverErrors.marital_status"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                name="p1"
                                label="C.U.R.P."
                                :valueInput="values.curp"
                                :rule="rules.curp"
                                @valueChange="
                                    (v) => {
                                        values.curp = v.trim();
                                        delete serverErrors.curp;
                                    }
                                "
                                :errorMessages="serverErrors.curp"
                            ></textFieldForm>
                        </v-col>

                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="R.F.C."
                                :valueInput="values.rfc"
                                :rule="rules.rfc"
                                @valueChange="
                                    (v) => {
                                        values.rfc = v.trim();
                                        delete serverErrors.rfc;
                                    }
                                "
                                :errorMessages="serverErrors.rfc"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                name="p1"
                                label="INFONAVIT"
                                :valueInput="values.infonavit_number"
                                :rule="rules.infonavit_number"
                                @valueChange="
                                    (v) => {
                                        values.infonavit_number = v.trim();
                                        delete serverErrors.infonavit_number;
                                    }
                                "
                                :errorMessages="serverErrors.infonavit_number"
                            ></textFieldForm>
                        </v-col>

                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="FONACOT"
                                :valueInput="values.fonacot_number"
                                :rule="rules.fonacot_number"
                                @valueChange="
                                    (v) => {
                                        values.fonacot_number = v.trim();
                                        delete serverErrors.fonacot_number;
                                    }
                                "
                                :errorMessages="serverErrors.fonacot_number"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                name="p1"
                                label="N.S.S."
                                :valueInput="values.nss"
                                :rule="rules.nss"
                                @valueChange="
                                    (v) => {
                                        values.nss = v.trim();
                                        delete serverErrors.nss;
                                    }
                                "
                                :errorMessages="serverErrors.nss"
                            ></textFieldForm>
                        </v-col>

                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Teléfono"
                                :valueInput="values.phone"
                                :rule="rules.phone"
                                @valueChange="
                                    (v) => {
                                        values.phone = v.trim();
                                        delete serverErrors.phone;
                                    }
                                "
                                :errorMessages="serverErrors.phone"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <autocompleteOneForm
                                name="p1"
                                label="Tipo de sangre"
                                :valueInput="values.blood_type"
                                :rule="[]"
                                :items="serverItems.blood_types"
                                @valueChange="
                                    (v) => {
                                        values.blood_type = v;
                                        delete serverErrors.blood_type;
                                    }
                                "
                                :errorMessages="serverErrors.blood_type"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <autocompleteMultipleForm
                                name="p1"
                                label="Enfermedades Crónicas"
                                :valueInput="values.diseases"
                                :rule="[]"
                                :items="serverItems.diseases"
                                @valueChange="
                                    (v) => {
                                        values.diseases = v;
                                        delete serverErrors.diseases;
                                    }
                                "
                                :errorMessages="serverErrors.diseases"
                            ></autocompleteMultipleForm>
                        </v-col>
                    </v-row>

                    <v-row v-if="otherDiseasesComputed">
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Otras enfermedades"
                                :valueInput="values.other_diseases"
                                :rule="[]"
                                @valueChange="
                                    (v) => {
                                        values.other_diseases = v.trim();
                                        delete serverErrors.other_diseases;
                                    }
                                "
                                :errorMessages="serverErrors.other_diseases"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <location-information
                                name="p1"
                                :valuesInput="values"
                                :rules="rules"
                                :serverErrors="serverErrors"
                                countryLabel="País de residencia"
                            ></location-information>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Calle"
                                :valueInput="values.street"
                                :rule="rules.street"
                                @valueChange="
                                    (v) => {
                                        values.street = v.trim();
                                        delete serverErrors.street;
                                    }
                                "
                                :errorMessages="serverErrors.street"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                name="p1"
                                label="No. Exterior"
                                :valueInput="values.ext_number"
                                :rule="rules.ext_number"
                                @valueChange="
                                    (v) => {
                                        values.ext_number = v.trim();
                                        delete serverErrors.ext_number;
                                    }
                                "
                                :errorMessages="serverErrors.ext_number"
                            ></textFieldForm>
                        </v-col>

                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="No. Interior"
                                :valueInput="values.int_number"
                                :rule="[]"
                                @valueChange="
                                    (v) => {
                                        values.int_number = v.trim();
                                        delete serverErrors.int_number;
                                    }
                                "
                                :errorMessages="serverErrors.int_number"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                name="p1"
                                label="Contacto de emergencia"
                                :valueInput="values.emergency_contact"
                                :rule="rules.emergency_contact"
                                @valueChange="
                                    (v) => {
                                        values.emergency_contact = v.trim();
                                        delete serverErrors.emergency_contact;
                                    }
                                "
                                :errorMessages="serverErrors.emergency_contact"
                            ></textFieldForm>
                        </v-col>

                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Teléfono de emergencia"
                                :valueInput="values.emergency_number"
                                :rule="rules.emergency_number"
                                @valueChange="
                                    (v) => {
                                        values.emergency_number = v.trim();
                                        delete serverErrors.emergency_number;
                                    }
                                "
                                :errorMessages="serverErrors.emergency_number"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <textFieldForm
                                name="p1"
                                label="Nombre completo del beneficiario"
                                :valueInput="values.payee_name"
                                :rule="[]"
                                @valueChange="
                                    (v) => {
                                        values.payee_name = v.trim();
                                        delete serverErrors.payee_name;
                                    }
                                "
                                :errorMessages="serverErrors.payee_name"
                            ></textFieldForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                name="p1"
                                label="Teléfono del beneficiario"
                                :valueInput="values.payee_family_phone"
                                :rule="rules.payee_family_phone"
                                @valueChange="
                                    (v) => {
                                        values.payee_family_phone = v.trim();
                                        delete serverErrors.payee_family_phone;
                                    }
                                "
                                :errorMessages="serverErrors.payee_family_phone"
                            ></textFieldForm>
                        </v-col>

                        <v-col>
                            <autocompleteOneForm
                                name="p1"
                                label="Parentesco del beneficiario"
                                :valueInput="values.payee_family_relationship"
                                :rule="[]"
                                :items="serverItems.payee_family_relationships"
                                @valueChange="
                                    (v) => {
                                        values.payee_family_relationship = v;
                                        delete serverErrors.payee_family_relationship;
                                    }
                                "
                                :errorMessages="
                                    serverErrors.payee_family_relationship
                                "
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <fileInputForm
                                name="p1"
                                label="Fotografía"
                                :multiple="false"
                                :valueInput="values.photo_file"
                                :url="values.photo_url"
                                :rule="rules.photo_file"
                                @valueChange="
                                    (v) => {
                                        values.photo_file = v;
                                        delete serverErrors.photo_file;
                                    }
                                "
                                :errorMessages="serverErrors.photo_file"
                                v-on:update:url="values.photo_url = $event"
                            ></fileInputForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <p
                                class="text-caption font-weight-black black--text mb-0"
                            >
                                Indica si el empleado tiene hijos y da clic en
                                agregar tantas veces como hijos tenga para
                                registrar los datos de cada uno
                            </p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" lg="6">
                            <switchForm
                                title="Tiene hijos"
                                leftLabel=""
                                rightLabel="Si"
                                :valueInput="values.has_childs"
                                :rule="[]"
                                @valueChange="
                                    (v) => {
                                        values.has_childs = v;
                                    }
                                "
                            ></switchForm>
                        </v-col>
                        <v-col v-if="values.has_childs">
                            <secondary-button
                                :props="addBtnData"
                                class="mr-2"
                            ></secondary-button>
                        </v-col>
                    </v-row>

                    <v-expand-transition
                        v-for="(itemOpt, indexChild) in values.children"
                        :key="indexChild"
                        appear
                    >
                        <v-row align="center">
                            <v-col cols="10" class="p-0">
                                <v-row>
                                    <v-col cols="6" class="pr-0">
                                        <autocompleteOneForm
                                            name="p1"
                                            label="Género"
                                            :valueInput="itemOpt.gender"
                                            :rule="rules.required"
                                            :items="serverItems.genders"
                                            @valueChange="
                                                (v) => {
                                                    itemOpt.gender = v;
                                                    delete serverErrors[
                                                        `children.${indexChild}.option_list.${indexChild}.option`
                                                    ];
                                                }
                                            "
                                            :errorMessages="
                                                serverErrors[
                                                    `children.${indexChild}.option_list.${indexChild}.option`
                                                ]
                                            "
                                        ></autocompleteOneForm>
                                    </v-col>
                                    <v-col cols="6" class="pr-0">
                                        <datePickerForm
                                            name="p1"
                                            label="Fecha de nacimiento"
                                            :valueInput="itemOpt.birthdate"
                                            :rule="rules.birthdate"
                                            @valueChange="
                                                (v) => {
                                                    itemOpt.birthdate = v;
                                                    delete serverErrors[
                                                        `children.${indexChild}.option_list.${indexChild}.option`
                                                    ];
                                                }
                                            "
                                            :errorMessages="
                                                serverErrors[
                                                    `children.${indexChild}.option_list.${indexChild}.option`
                                                ]
                                            "
                                        ></datePickerForm>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="2" align-self="center">
                                <v-row justify-sm="end" class="mr-0">
                                    <iconButton
                                        :props="{
                                            color: 'warning',
                                            icon: 'mdi-close',
                                            height: '39',
                                            click: () => {
                                                removeChild(indexChild);
                                            },
                                        }"
                                    ></iconButton>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </div>
            </v-tab-item>

            <!--STEP 2  "Documentación" -->
            <v-tab-item value="2">
                <div class="mt-3">
                    <v-row>
                        <v-col class="ml-13">
                            <span class="body-2"
                                >Marque la casilla de la izquierda cuando el
                                archivo sea requerido en la documentación del
                                empleado</span
                            >
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="
                                        values.birthCertificate_required
                                    "
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.birthCertificate_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Acta de nacimiento"
                                    :multiple="false"
                                    :valueInput="values.birthCertificate_file"
                                    :url="values.birthCertificate_url"
                                    :rule="rules.birthCertificate_file"
                                    @valueChange="
                                        (v) => {
                                            values.birthCertificate_file = v;
                                            delete serverErrors[
                                                `documentation.ACTA DE NACIMIENTO`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.ACTA DE NACIMIENTO`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.birthCertificate_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.ine_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.ine_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Identificación oficial (INE)"
                                    :multiple="false"
                                    :valueInput="values.ine_file"
                                    :url="values.ine_url"
                                    :rule="rules.ine_file"
                                    @valueChange="
                                        (v) => {
                                            values.ine_file = v;
                                            delete serverErrors[
                                                `documentation.IDENTIFICACIÓN OFICIAL`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.IDENTIFICACIÓN OFICIAL`
                                        ]
                                    "
                                    v-on:update:url="values.ine_url = $event"
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.curp_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.curp_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="C.U.R.P."
                                    :multiple="false"
                                    :valueInput="values.curp_file"
                                    :url="values.curp_url"
                                    :rule="rules.curp_file"
                                    @valueChange="
                                        (v) => {
                                            values.curp_file = v;
                                            delete serverErrors[
                                                `documentation.CURP`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[`documentation.CURP`]
                                    "
                                    v-on:update:url="values.curp_url = $event"
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.rfc_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.rfc_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="R.F.C."
                                    :multiple="false"
                                    :valueInput="values.rfc_file"
                                    :url="values.rfc_url"
                                    :rule="rules.rfc_file"
                                    @valueChange="
                                        (v) => {
                                            values.rfc_file = v;
                                            delete serverErrors[
                                                `documentation.RFC`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[`documentation.RFC`]
                                    "
                                    v-on:update:url="values.rfc_url = $event"
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.nss_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.nss_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="N.S.S."
                                    :multiple="false"
                                    :valueInput="values.nss_file"
                                    :url="values.nss_url"
                                    :rule="rules.nss_file"
                                    @valueChange="
                                        (v) => {
                                            values.nss_file = v;
                                            delete serverErrors[
                                                `documentation.NSS`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[`documentation.NSS`]
                                    "
                                    v-on:update:url="values.nss_url = $event"
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.proofResidency_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.proofResidency_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Comprobante de domicilio"
                                    :multiple="false"
                                    :valueInput="values.proofResidency_file"
                                    :url="values.proofResidency_url"
                                    :rule="rules.proofResidency_file"
                                    @valueChange="
                                        (v) => {
                                            values.proofResidency_file = v;
                                            delete serverErrors[
                                                `documentation.COMPROBANTE DE DOMICILIO`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.COMPROBANTE DE DOMICILIO`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.proofResidency_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.studyCert_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.studyCert_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Último certificado de estudios"
                                    :multiple="false"
                                    :valueInput="values.studyCert_file"
                                    :url="values.studyCert_url"
                                    :rule="rules.studyCert_file"
                                    @valueChange="
                                        (v) => {
                                            values.studyCert_file = v;
                                            delete serverErrors[
                                                `documentation.CERTIFICADO DE ESTUDIOS`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.CERTIFICADO DE ESTUDIOS`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.studyCert_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.economyStudy_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.economyStudy_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Estudio socioeconómico"
                                    :multiple="false"
                                    :valueInput="values.economyStudy_file"
                                    :url="values.economyStudy_url"
                                    :rule="rules.economyStudy_file"
                                    @valueChange="
                                        (v) => {
                                            values.economyStudy_file = v;
                                            delete serverErrors[
                                                `documentation.ESTUDIO SOCIOECONÓMICO`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.ESTUDIO SOCIOECONÓMICO`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.economyStudy_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="
                                        values.recomendationLetter_required
                                    "
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.recomendationLetter_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Carta de recomendación"
                                    :multiple="false"
                                    :valueInput="
                                        values.recomendationLetter_file
                                    "
                                    :url="values.recomendationLetter_url"
                                    :rule="rules.recomendationLetter_file"
                                    @valueChange="
                                        (v) => {
                                            values.recomendationLetter_file = v;
                                            delete serverErrors[
                                                `documentation.CARTA DE RECOMENDACIÓN`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.CARTA DE RECOMENDACIÓN`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.recomendationLetter_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="
                                        values.psychometricTest_required
                                    "
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.psychometricTest_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Pruebas psicométricas"
                                    :multiple="false"
                                    :valueInput="values.psychometricTest_file"
                                    :rule="rules.psychometricTest_file"
                                    :url="values.psychometricTest_url"
                                    @valueChange="
                                        (v) => {
                                            values.psychometricTest_file = v;
                                            delete serverErrors[
                                                `documentation.PRUEBA PSICOMÉTRICA`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.PRUEBA PSICOMÉTRICA`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.psychometricTest_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.internalRules_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.internalRules_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Reglamento interno de trabajo"
                                    :multiple="false"
                                    :valueInput="values.internalRules_file"
                                    :url="values.internalRules_url"
                                    :rule="rules.internalRules_file"
                                    @valueChange="
                                        (v) => {
                                            values.internalRules_file = v;
                                            delete serverErrors[
                                                `documentation.REGLAMENTO INTERNO`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.REGLAMENTO INTERNO`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.internalRules_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.confidential_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.confidential_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Convenio de confidencialidad"
                                    :multiple="false"
                                    :valueInput="values.confidential_file"
                                    :url="values.confidential_url"
                                    :rule="rules.confidential_file"
                                    @valueChange="
                                        (v) => {
                                            values.confidential_file = v;
                                            delete serverErrors[
                                                `documentation.CONVENCIO CONFIDENCIAL`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.CONVENCIO CONFIDENCIAL`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.confidential_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.payment_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.payment_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Propuesta económica"
                                    :multiple="false"
                                    :valueInput="values.payment_file"
                                    :url="values.payment_url"
                                    :rule="rules.payment_file"
                                    @valueChange="
                                        (v) => {
                                            values.payment_file = v;
                                            delete serverErrors[
                                                `documentation.PROPUESTA ECONÓMICA`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.PROPUESTA ECONÓMICA`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.payment_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.ret_infonavit_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.ret_infonavit_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Aviso de retención INFONAVIT"
                                    :multiple="false"
                                    :valueInput="values.ret_infonavit_file"
                                    :url="values.ret_infonavit_url"
                                    :rule="rules.ret_infonavit_file"
                                    @valueChange="
                                        (v) => {
                                            values.ret_infonavit_file = v;
                                            delete serverErrors[
                                                `documentation.AVISO DE RETENCIÓN INFONAVIT`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.AVISO DE RETENCIÓN INFONAVIT`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.ret_infonavit_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.ret_fonacot_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.ret_fonacot_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Aviso de retención FONACOT"
                                    :multiple="false"
                                    :valueInput="values.ret_fonacot_file"
                                    :url="values.ret_fonacot_url"
                                    :rule="rules.ret_fonacot_file"
                                    @valueChange="
                                        (v) => {
                                            values.ret_fonacot_file = v;
                                            delete serverErrors[
                                                `documentation.AVISO DE RETENCIÓN FONACOT`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.AVISO DE RETENCIÓN FONACOT`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.ret_fonacot_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.epp_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.epp_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Vale de equipo de protección personal (opcional)"
                                    :multiple="false"
                                    :valueInput="values.epp_file"
                                    :url="values.epp_url"
                                    :rule="rules.epp_file"
                                    @valueChange="
                                        (v) => {
                                            values.epp_file = v;
                                            delete serverErrors[
                                                `documentation.VALE DE EQUIPO DE PROTECCIÓN PERSONAL`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.VALE DE EQUIPO DE PROTECCIÓN PERSONAL`
                                        ]
                                    "
                                    v-on:update:url="values.epp_url = $event"
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="
                                        values.waiver_equipment_required
                                    "
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.waiver_equipment_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Carta responsiva de equipo (opcional)"
                                    :multiple="false"
                                    :valueInput="values.waiver_equipment_file"
                                    :url="values.waiver_equipment_url"
                                    :rule="rules.waiver_equipment_file"
                                    @valueChange="
                                        (v) => {
                                            values.waiver_equipment_file = v;
                                            delete serverErrors[
                                                `documentation.CARTA RESPONSIVA DE EQUIPO`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.CARTA RESPONSIVA DE EQUIPO`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.waiver_equipment_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.medicalTest_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.medicalTest_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Examen médico (opcional)"
                                    :multiple="false"
                                    :valueInput="values.medicalTest_file"
                                    :url="values.medicalTest_url"
                                    :rule="rules.medicalTest_file"
                                    @valueChange="
                                        (v) => {
                                            values.medicalTest_file = v;
                                            delete serverErrors[
                                                `documentation.EXAMEN MÉDICO`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.EXAMEN MÉDICO`
                                        ]
                                    "
                                    v-on:update:url="
                                        values.medicalTest_url = $event
                                    "
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <checkboxForm
                                    label=""
                                    :valueInput="values.letter_required"
                                    :rule="[]"
                                    @valueChange="
                                        (v) => {
                                            values.letter_required =
                                                v == true ? 1 : 0;
                                        }
                                    "
                                    class="mx-3"
                                ></checkboxForm>
                                <fileInputForm
                                    name="p2"
                                    label="Carta de no antecedentes penales (opcional)"
                                    :multiple="false"
                                    :valueInput="values.letter_file"
                                    :url="values.letter_url"
                                    :rule="rules.letter_file"
                                    @valueChange="
                                        (v) => {
                                            values.letter_file = v;
                                            delete serverErrors[
                                                `documentation.ANTECEDENTES PENALES`
                                            ];
                                        }
                                    "
                                    :errorMessages="
                                        serverErrors[
                                            `documentation.ANTECEDENTES PENALES`
                                        ]
                                    "
                                    v-on:update:url="values.letter_url = $event"
                                    class="width-available"
                                ></fileInputForm>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-tab-item>

            <!-- STEP 3 "Contratación" -->
            <v-tab-item value="3">
                <div class="mt-3">
                    <v-row>
                        <v-col cols="6">
                            <autocompleteOneForm
                                name="p3"
                                label="Empresa"
                                :valueInput="values.company"
                                :rule="rules.company"
                                :items="serverItems.companies"
                                :loading="loadingCompanies"
                                @valueChange="
                                    (v) => {
                                        values.company = v;
                                        getLocations(v);
                                        getPositions(v);
                                        values.location = '';
                                    }
                                "
                            ></autocompleteOneForm>
                        </v-col>

                        <v-col>
                            <autocompleteOneForm
                                name="p3"
                                label="Locación"
                                :valueInput="values.branch_office_id"
                                :rule="rules.branch_office_id"
                                :items="serverItems.locations"
                                :loading="loadingLocations"
                                @valueChange="
                                    (v) => {
                                        values.branch_office_id = v;
                                        delete serverErrors[
                                            `contract.branch_office_id`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.branch_office_id`]
                                "
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                          <autocompleteOneForm
                              name="p3"
                              label="Departamento"
                              :valueInput="values.department_id"
                              :rule="rules.required"
                              :items="serverItems.departments"
                              :loading="loadingDepartments"
                              @valueChange="
                                  (v) => {
                                      values.department_id = v;
                                      delete serverErrors[
                                          `contract.department_id`
                                      ];
                                  }
                              "
                              :errorMessages="
                                  serverErrors[`contract.department_id`]
                              "
                          ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <datePickerForm
                                name="p3"
                                label="Fecha de ingreso"
                                :valueInput="values.admission_date"
                                :rule="rules.admission_date"
                                @valueChange="
                                    (v) => {
                                        values.admission_date = v;
                                        delete serverErrors[
                                            `contract.admission_date`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.admission_date`]
                                "
                            ></datePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <autocompleteOneForm
                                name="p3"
                                label="Tipo de contrato"
                                :valueInput="values.type"
                                :rule="rules.type"
                                :items="serverItems.contactTypes"
                                @valueChange="
                                    (v) => {
                                        values.type = v;
                                        delete serverErrors[`contract.type`];
                                    }
                                "
                                :errorMessages="serverErrors[`contract.type`]"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <!-- DATES FOR "PERIODO DE PRUEBA" CONTRACT -->
                    <v-row v-if="values.type == 'PERIODO DE PRUEBA'">
                        <v-col>
                            <datePickerForm
                                name="p3"
                                label="Fecha de inicio del contrato"
                                :valueInput="values.start_date"
                                :rule="rules.start_date"
                                @valueChange="
                                    (v) => {
                                        values.start_date = v;
                                        delete serverErrors[
                                            `contract.start_date`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.start_date`]
                                "
                            ></datePickerForm>
                        </v-col>
                        <v-col>
                            <datePickerForm
                                name="p3"
                                label="Fecha de final del contrato"
                                :valueInput="values.end_date"
                                :rule="rules.end_date"
                                @valueChange="
                                    (v) => {
                                        values.end_date = v;
                                        delete serverErrors[
                                            `contract.end_date`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.end_date`]
                                "
                            ></datePickerForm>
                        </v-col>
                    </v-row>
                    <!--  -->

                    <v-row>
                        <v-col>
                            <autocompleteOneForm
                                name="p3"
                                label="Modalidad"
                                :valueInput="values.mode"
                                :rule="rules.mode"
                                :items="serverItems.modes"
                                @valueChange="
                                    (v) => {
                                        values.mode = v;
                                        delete serverErrors[`contract.mode`];
                                    }
                                "
                                :errorMessages="serverErrors[`contract.mode`]"
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <autocompleteOneForm
                                name="p3"
                                label="Puesto"
                                :valueInput="values.position_id"
                                :rule="rules.position_id"
                                :items="serverItems.positions"
                                @valueChange="
                                    (v) => {
                                        values.position_id = v;
                                        delete serverErrors[
                                            `contract.position_id`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.position_id`]
                                "
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <autocompleteOneForm
                                name="p3"
                                label="Periodicidad de pago"
                                :valueInput="values.payroll_type"
                                :rule="rules.payroll_type"
                                :items="serverItems.payroll_types"
                                @valueChange="
                                    (v) => {
                                        values.payroll_type = v;
                                        delete serverErrors[
                                            `contract.payroll_type`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.payroll_type`]
                                "
                            ></autocompleteOneForm>
                        </v-col>
                    </v-row>

                    <!-- <v-row>
          <v-col cols="6">
            <textFieldForm
              name="p3"
              label="Salario mensual (fiscal)"
              :valueInput="values.fiscal_salary"
              :rule="rules.fiscal_salary"
              @valueChange="(v) => {values.fiscal_salary = v.trim(); delete serverErrors[`contract.fiscal_salary`]}"
              :errorMessages="serverErrors[`contract.fiscal_salary`]"
            ></textFieldForm>
          </v-col>

          <v-col>
            <textFieldForm
              name="p3"
              label="Salario mensual (integradora)"
              :valueInput="values.integrated_salary"
              :rule="rules.integrated_salary"
              @valueChange="(v) => {values.integrated_salary = v.trim(); delete serverErrors[`contract.integrated_salary`]}"
              :errorMessages="serverErrors[`contract.integrated_salary`]"
            ></textFieldForm>
          </v-col>
        </v-row> -->

                    <v-row>
                        <v-col>
                            <switchForm
                                name="p3"
                                title="Requiere apoyo para transporte"
                                leftLabel=""
                                rightLabel="Si"
                                :valueInput="values.support_transportation"
                                :rule="rules.support_transportation"
                                @valueChange="
                                    (v) => {
                                        values.support_transportation = v;
                                        delete serverErrors[
                                            `support_transportation`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.support_transportation`
                                    ]
                                "
                            ></switchForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <switchForm
                                name="p3"
                                title="Debe registrar horario de comida"
                                leftLabel=""
                                rightLabel="Si"
                                :valueInput="values.meal_schedule"
                                :rule="rules.meal_schedule"
                                @valueChange="
                                    (v) => {
                                        values.meal_schedule = v;
                                        delete serverErrors[
                                            `contract.meal_schedule`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.meal_schedule`]
                                "
                            ></switchForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <switchForm
                                name="p3"
                                title="Recibe compensación por horas extra"
                                leftLabel=""
                                rightLabel="Si"
                                :valueInput="values.has_overtime_payment"
                                :rule="[]"
                                @valueChange="
                                    (v) => {
                                        values.has_overtime_payment = v;
                                        delete serverErrors[
                                            `contract.has_overtime_payment`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.has_overtime_payment`
                                    ]
                                "
                            ></switchForm>
                        </v-col>
                    </v-row>

                    <v-row justify="start">
                        <v-col class="text-left col-md-6">
                            <div class="font-weight-bold body-2">
                                Horarios Laborales
                            </div>
                        </v-col>
                    </v-row>

                    <v-row justify="start">
                        <v-col class="my-0 py-0">
                            <checkboxForm
                                name="p3"
                                label="Repetir de lunes a viernes"
                                :valueInput="values.repeat_schedule"
                                :rule="rules.repeat_schedule"
                                @valueChange="
                                    (v) => {
                                        values.repeat_schedule = v;
                                        delete serverErrors[
                                            `contract.repeat_schedule`
                                        ];
                                        this.checkRepeat();
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.repeat_schedule`]
                                "
                            ></checkboxForm>
                        </v-col>
                    </v-row>

                    <v-row class="less-margin mt-5 pt-0">
                        <v-col cols="6">
                            <timePickerForm
                                name="p3"
                                label="Horario lunes entrada"
                                :valueInput="values.monday_schedule_start"
                                :rule="rules.monday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.monday_schedule_start = v;
                                        delete serverErrors[
                                            `contract.monday_schedule_start`
                                        ];
                                        this.checkRepeat();
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.monday_schedule_start`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>

                        <v-col>
                            <timePickerForm
                                name="p3"
                                label="Horario lunes salida"
                                :valueInput="values.monday_schedule_end"
                                :rule="rules.monday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.monday_schedule_end = v;
                                        delete serverErrors[
                                            `contract.monday_schedule_end`
                                        ];
                                        this.checkRepeat();
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.monday_schedule_end`]
                                "
                            ></timePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <timePickerForm
                                name="p3"
                                label="Horario martes entrada"
                                :valueInput="values.tuesday_schedule_start"
                                :rule="rules.tuesday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.tuesday_schedule_start = v;
                                        delete serverErrors[
                                            `contract.tuesday_schedule_start`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.tuesday_schedule_start`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>

                        <v-col>
                            <timePickerForm
                                name="p3"
                                label="Horario martes salida"
                                :valueInput="values.tuesday_schedule_end"
                                :rule="rules.tuesday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.tuesday_schedule_end = v;
                                        delete serverErrors[
                                            `contract.tuesday_schedule_end`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.tuesday_schedule_end`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <timePickerForm
                                name="p3"
                                label="Horario miércoles entrada"
                                :valueInput="values.wednesday_schedule_start"
                                :rule="rules.wednesday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.wednesday_schedule_start = v;
                                        delete serverErrors[
                                            `contract.wednesday_schedule_start`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.wednesday_schedule_start`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>

                        <v-col>
                            <timePickerForm
                                name="p3"
                                label="Horario miércoles salida"
                                :valueInput="values.wednesday_schedule_end"
                                :rule="rules.wednesday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.wednesday_schedule_end = v;
                                        delete serverErrors[
                                            `contract.wednesday_schedule_end`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.wednesday_schedule_end`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <timePickerForm
                                name="p3"
                                label="Horario jueves entrada"
                                :valueInput="values.thursday_schedule_start"
                                :rule="rules.thursday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.thursday_schedule_start = v;
                                        delete serverErrors[
                                            `contract.thursday_schedule_start`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.thursday_schedule_start`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>

                        <v-col>
                            <timePickerForm
                                name="p3"
                                label="Horario jueves salida"
                                :valueInput="values.thursday_schedule_end"
                                :rule="rules.thursday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.thursday_schedule_end = v;
                                        delete serverErrors[
                                            `contract.thursday_schedule_end`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.thursday_schedule_end`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <timePickerForm
                                name="p3"
                                label="Horario viernes entrada"
                                :valueInput="values.friday_schedule_start"
                                :rule="rules.friday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.friday_schedule_start = v;
                                        delete serverErrors[
                                            `contract.friday_schedule_start`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.friday_schedule_start`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>

                        <v-col>
                            <timePickerForm
                                name="p3"
                                label="Horario viernes salida"
                                :valueInput="values.friday_schedule_end"
                                :rule="rules.friday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.friday_schedule_end = v;
                                        delete serverErrors[
                                            `contract.friday_schedule_end`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.friday_schedule_end`]
                                "
                            ></timePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <timePickerForm
                                name="p3"
                                label="Horario sábado entrada"
                                :valueInput="values.saturday_schedule_start"
                                :rule="rules.saturday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.saturday_schedule_start = v;
                                        delete serverErrors[
                                            `contract.saturday_schedule_start`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.saturday_schedule_start`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>

                        <v-col>
                            <timePickerForm
                                name="p3"
                                label="Horario sábado salida"
                                :valueInput="values.saturday_schedule_end"
                                :rule="rules.saturday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.saturday_schedule_end = v;
                                        delete serverErrors[
                                            `contract.saturday_schedule_end`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.saturday_schedule_end`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <timePickerForm
                                name="p3"
                                label="Horario domingo entrada"
                                :valueInput="values.sunday_schedule_start"
                                :rule="rules.sunday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.sunday_schedule_start = v;
                                        delete serverErrors[
                                            `contract.sunday_schedule_start`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[
                                        `contract.sunday_schedule_start`
                                    ]
                                "
                            ></timePickerForm>
                        </v-col>

                        <v-col>
                            <timePickerForm
                                name="p3"
                                label="Horario domingo salida"
                                :valueInput="values.sunday_schedule_end"
                                :rule="rules.sunday_schedule"
                                @valueChange="
                                    (v) => {
                                        values.sunday_schedule_end = v;
                                        delete serverErrors[
                                            `contract.sunday_schedule_end`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.sunday_schedule_end`]
                                "
                            ></timePickerForm>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <fileInputForm
                                name="p3"
                                label="PDF de contratos"
                                :multiple="true"
                                :valueInput="values.contract_file"
                                :url="values.contract_path"
                                :rule="[]"
                                @valueChange="
                                    (v) => {
                                        values.contract_file = v;
                                        delete serverErrors[
                                            `contract.contract_file`
                                        ];
                                    }
                                "
                                :errorMessages="
                                    serverErrors[`contract.contract_file`]
                                "
                                v-on:update:url="values.contract_path = $event"
                            ></fileInputForm>
                        </v-col>
                    </v-row>

                    <v-row
                        v-for="(item, index) in values.contract_multifiles"
                        :key="index"
                    >
                        <v-col>
                            <FilePrevFormVue
                                :nameImg="item.name"
                                :url="item.url"
                                v-on:update:url="
                                    item.url = $event;
                                    removeFile(index);
                                "
                            ></FilePrevFormVue>
                        </v-col>
                    </v-row>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import FilePrevFormVue from "../../../../components/FilePrevForm.vue";
import LocationInformation from "../../../../components/LocationInformation.vue";
/* eslint-disable */
export default {
    props: ["values", "stepIn", "stepClasses", "serverErrors"],
    components: {
        "location-information": LocationInformation,
        FilePrevFormVue: FilePrevFormVue,
    },
    model: {
        event: "tabChanged",
    },
    watch: {
        ["values.company"]: function (v) {
            console.log(v, 999);
            this.getPositions(v);
            this.getLocations(v);
        },
        ["values.repeat_schedule"]: function (v) {
            if (v) {
                this.checkRepeat();
            }
        },
        ["values.has_childs"]: function (v) {
            if (!v) {
                this.values.children = [];
            }
        },
    },
    computed: {
        tab: {
            get: function () {
                return this.stepIn;
            },
            set: function (value) {
                //console.log(value)
                this.setRules(value);
                this.$emit("tabChanged", value);
                //this.tab = stepIn;
            },
        },

        stClasses: {
            get: function () {
                return this.stepClasses;
            },
            set: function (value) {
                //console.log(value)
                //  this.$emit('tabChanged', value)
                //this.tab = stepIn;
            },
        },

        otherDiseasesComputed: {
            get: function () {
                const found = this.values.diseases.find(
                    (item) => item == "OTROS"
                );
                console.log(found);
                if (found == undefined) return false;
                return true;
            },
        },
    },
    data() {
        return {
            e1: 1,
            //tab: '',
            show4: false,
            loadingCompanies: false,
            loadingLocations: false,
            loadingDepartments: false,

            rules: {},

            rulesOne: {
                employe_number: [
                    (v) => {
                        if (!v) return "El campo es requerido";
                        if (v != null && !v.match("^[0-9]*$"))
                            return "Solo números";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }

                        return true;
                    },
                ],
                name: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                required: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                email: [
                    //v => !!v || 'El campo es requerido'
                    (v) => {
                        if (!v) return "El campo es requerido";
                        if (v.length > 0) {
                            const pattern =
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return pattern.test(v) || "Correo no válido";
                        }
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                business_email: [
                    (v) => {
                        if (v.length > 0) {
                            try {
                                if (v.trim() == "") return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                            const pattern =
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return pattern.test(v) || "Correo no válido";
                        }
                        return true;
                    },
                ],
                birthplace: [(v) => !!v || "El campo es requerido"],
                birthdate: [(v) => !!v || "El campo es requerido"],
                curp: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                rfc: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                nss: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                phone: [
                    (v) => {
                        if (!v) return "El campo es requerido";
                        if (v != null && !v.match("^[0-9]*$"))
                            return "Solo números";
                        if (v.length != 10) {
                            return "Máximo 10 caracteres";
                        }
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                payee_family_phone: [
                    (v) => {
                        if (v != null && !v.match("^[0-9]*$"))
                            return "Solo números";
                        if (v.length > 10) {
                            return "Máximo 10 caracteres";
                        }
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                infonavit_number: [
                    (v) => !!v || "El campo es requerido",
                    (v) => {
                        if (v != null && !v.match("^[0-9]*$"))
                            return "Solo números";
                        if (v.length > 10) {
                            return "Máximo 10 caracteres";
                        }
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                fonacot_number: [
                    (v) => !!v || "El campo es requerido",
                    (v) => {
                        // if(v!= null && !v.match("^[0-9]*$"))
                        // return 'Solo números';
                        if (v.length > 5) {
                            return "Máximo 5 caracteres";
                        }
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                country: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                zip_code: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                neighborhood: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                city: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                state: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                street: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                ext_number: [
                    (v) => {
                        if (!v) return "El campo es requerido";
                        if (v != null && !v.match("^[0-9]*$"))
                            return "Solo números";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }

                        return true;
                    },
                ],
                int_number: [
                    (v) => {
                        if (v.length > 0) {
                            try {
                                if (v.trim() == "") return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                        }
                        return true;
                    },
                ],
                emergency_contact: [
                    (v) => {
                        if (v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                emergency_number: [
                    (v) => {
                        if (!v) return "El campo es requerido";
                        if (v != null && !v.match("^[0-9]*$"))
                            return "Solo números";
                        if (v.length < 10) {
                            return "Máximo 10 caracteres";
                        }
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
                photo_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //     return 'El campo es requerido';
                        return true;
                    },
                ],
            },
            //!part 2
            rulesTwo: {
                birthCertificate_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                ine_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                curp_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                rfc_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                nss_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                proofResidency_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                studyCert_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                economyStudy_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                recomendationLetter_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                psychometricTest_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                internalRules_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                confidential_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                payment_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                medicalTest_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                letter_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                ret_infonavit_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                ret_fonacot_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                epp_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
                waiver_equipment_file: [
                    (v) => {
                        // if(v == null || v.length == 0)
                        //   return 'El campo es requerido';
                        return true;
                    },
                ],
            },
            //!part 3
            rulesThree: {
                required: [(v) => !!v || "El campo es requerido"],
                company: [(v) => !!v || "El campo es requerido"],
                branch_office_id: [(v) => !!v || "El campo es requerido"],
                admission_date: [(v) => !!v || "El campo es requerido"],
                type: [(v) => !!v || "El campo es requerido"],
                mode: [(v) => !!v || "El campo es requerido"],
                position_id: [(v) => !!v || "El campo es requerido"],
                payroll_type: [(v) => !!v || "El campo es requerido"],
                fiscal_salary: [
                    (v) => {
                        if (
                            v != null &&
                            !v.toString().match("^[0-9]+(.[0-9]{1,2})?$")
                        )
                            return "Solo números válidos";
                        if (!v) return "El campo es requerido";
                        return true;
                    },
                ],
                integrated_salary: [
                    (v) => {
                        if (
                            v != null &&
                            !v.toString().match("^[0-9]+(.[0-9]{1,2})?$")
                        )
                            return "Solo números válidos";
                        if (!v) return "El campo es requerido";
                        return true;
                    },
                ],

                monday_schedule: [
                    (v) => {
                        // if(v!= null && !v.match("^([0-9]{1,2})+(\:[0-9]{1,2})+(\ ?)+\-+(\ ?)+([0-9]{1,2})+(\:[0-9]{1,2})$"))
                        //   return 'Solo números en formato: X:XX - X:XX';
                        // if(!v)
                        // return 'El campo es requerido'
                        return true;
                    },
                ],
                tuesday_schedule: [
                    (v) => {
                        // if(v!= null && !v.match("^([0-9]{1,2})+(\:[0-9]{1,2})+(\ ?)+\-+(\ ?)+([0-9]{1,2})+(\:[0-9]{1,2})$"))
                        //   return 'Solo números en formato: X:XX - X:XX';
                        // if(!v)
                        // return 'El campo es requerido'
                        return true;
                    },
                ],
                wednesday_schedule: [
                    (v) => {
                        // if(v!= null && !v.match("^([0-9]{1,2})+(\:[0-9]{1,2})+(\ ?)+\-+(\ ?)+([0-9]{1,2})+(\:[0-9]{1,2})$"))
                        //   return 'Solo números en formato: X:XX - X:XX';
                        // if(!v)
                        // return 'El campo es requerido'
                        return true;
                    },
                ],
                thursday_schedule: [
                    (v) => {
                        // if(v!= null && !v.match("^([0-9]{1,2})+(\:[0-9]{1,2})+(\ ?)+\-+(\ ?)+([0-9]{1,2})+(\:[0-9]{1,2})$"))
                        //   return 'Solo números en formato: X:XX - X:XX';
                        // if(!v)
                        // return 'El campo es requerido'
                        return true;
                    },
                ],
                friday_schedule: [
                    (v) => {
                        // if(v!= null && !v.match("^([0-9]{1,2})+(\:[0-9]{1,2})+(\ ?)+\-+(\ ?)+([0-9]{1,2})+(\:[0-9]{1,2})$"))
                        //   return 'Solo números en formato: X:XX - X:XX';
                        // if(!v)
                        // return 'El campo es requerido'
                        return true;
                    },
                ],
                saturday_schedule: [
                    (v) => {
                        // if(v!= null && !v.match("^([0-9]{1,2})+(\:[0-9]{1,2})+(\ ?)+\-+(\ ?)+([0-9]{1,2})+(\:[0-9]{1,2})$"))
                        //   return 'Solo números en formato: X:XX - X:XX';
                        // if(!v)
                        //   return 'El campo es requerido'
                        return true;
                    },
                ],
                sunday_schedule: [
                    (v) => {
                        // if(v!= null && !v.match("^([0-9]{1,2})+(\:[0-9]{1,2})+(\ ?)+\-+(\ ?)+([0-9]{1,2})+(\:[0-9]{1,2})$"))
                        //   return 'Solo números en formato: X:XX - X:XX';
                        // if(!v)
                        //   return 'El campo es requerido'
                        return true;
                    },
                ],

                amount: [
                    (v) => {
                        if (v != null && !v.match("^[0-9]+(.[0-9]{1,2})?$"))
                            return "Solo números válidos";
                        if (!v) return "El campo es requerido";
                        try {
                            if (v.trim() == "") return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    },
                ],
            },
            serverItems: {
                departments: [],
                nationalities: [],
                genders: [
                    //Masculino, Femenino, No Binario, Otro
                    { text: "Masculino", value: "MASCULINO" },
                    { text: "Femenino", value: "FEMENINO" },
                    { text: "No Binario", value: "NO BINARIO" },
                    { text: "Otro", value: "OTRO" },
                ],
                marital_statuses: [
                    //Soltero, Casado, Unión Libre
                    { text: "Soltero", value: "SOLTERO" },
                    { text: "Casado", value: "CASADO" },
                    { text: "Unión Libre", value: "UNIÓN LIBRE" },
                    { text: "Viudo", value: "VIUDO" },
                ],
                blood_types: [
                    //A+, A-, B+, B-, AB+, AB-, O+, O-
                    { text: "A+", value: "A+" },
                    { text: "A-", value: "A-" },
                    { text: "B+", value: "B+" },
                    { text: "B-", value: "B-" },
                    { text: "AB+", value: "AB+" },
                    { text: "AB-", value: "AB-" },
                    { text: "O+", value: "O+" },
                    { text: "O-", value: "O-" },
                ],
                diseases: [
                    // {text: "Asma", value: "ASMA"},
                    // {text: "Diabetes", value: "DIABETES"},
                    // {text: "Otro", value: "OTRO"},
                    { text: "Diabetes mellitus", value: "DIABETES MELLITUS" },
                    {
                        text: "hipertensión arterial sistémica",
                        value: "HIPERTENSIÓN ARTERIAL SISTÉMICA",
                    },
                    {
                        text: "Enfermedad pulmonar",
                        value: "ENFERMEDAD PULMONAR",
                    },
                    { text: "Asma", value: "ASMA" },
                    {
                        text: "Enfermedad cardiovascular",
                        value: "ENFERMEDAD CARDIOVASCULAR",
                    },
                    {
                        text: "Enfermedad cerebrovascular",
                        value: "ENFERMEDAD CEREBROVASCULAR",
                    },
                    {
                        text: "Enfermedad renal Crónica",
                        value: "ENFERMEDAD RENAL CRÓNICA",
                    },
                    {
                        text: "Enfermedad reumatológica",
                        value: "ENFERMEDAD REUMATOLÓGICA",
                    },
                    { text: "VIH", value: "VIH" },
                    { text: "Otros", value: "OTROS" },
                ],
                payee_family_relationships: [
                    { text: "Madre", value: "MADRE" },
                    { text: "Padre", value: "PADRE" },
                    { text: "Hijo/a", value: "HIJO/A" },
                    { text: "Abuelo/a", value: "ABUELO/A" },
                    { text: "Nieto/a", value: "NIETO/A" },
                    { text: "Hermano/a", value: "HERMANO/A" },
                    { text: "Tío/a", value: "TÍO/A" },
                    { text: "Sobrino/a", value: "SOBRINO/A" },
                    { text: "Primo/a", value: "PRIMO/A" },
                    { text: "Cónyuge", value: "CÓNYUGE" },
                    { text: "Esposo/a", value: "ESPOSO/A" },
                    { text: "Otro", value: "OTRO" },
                ],
                employees: [{ text: "texto em", value: "1" }],
                companies: [],
                locations: [],
                contactTypes: [
                    { text: "Indeterminado", value: "INDETERMINADO" },
                    { text: "Periodo de prueba", value: "PERIODO DE PRUEBA" },
                ],
                modes: [
                    { text: "Presencial", value: "PRESENCIAL" },
                    { text: "Remota", value: "REMOTA" },
                    { text: "Híbrida", value: "HÍBRIDA" },
                ],
                positions: [{ text: "test l", value: "1" }],
                payroll_types: [
                    { text: "Semanal", value: "SEMANAL" },
                    { text: "Quincenal", value: "QUINCENAL" },
                    { text: "Catorcenal", value: "CATORCENAL" },
                    { text: "Mensual", value: "MENSUAL" },
                ],
            },
            addBtnData: {
                text: "Agregar Hijo/a/e",
                icon: "",
                click: this.addChild,
                color: "primary",
                loading: false,
                block: true,
            },
        };
    },
    mounted() {
        this.getCountries();
        this.getCompanies();
        this.getDepartments();
        this.getLocations(this.values.company ?? "");
        this.setRules(1);
        this.getPositions(this.values.company ?? "");
    },
    methods: {
        addChild() {
            if (!this.values.has_childs) return;

            this.values.children.push({ birthdate: "", gender: "" });
        },

        removeChild(index) {
            this.values.children.splice(index, 1);
        },

        /**
         * Set form rules by step
         */
        setRules(step) {
            this.rules = {};
            if (step == 1) {
                this.rules = {
                    ...this.rulesOne,
                    ...this.rulesTwo,
                };
                return;
            } else if (step == 2) {
                this.rules = {
                    ...this.rulesOne,
                    ...this.rulesTwo,
                };
                return;
            }

            this.rules = {
                ...this.rulesOne,
                ...this.rulesTwo,
                ...this.rulesThree,
            };
        },

        /**
            Repeat the value in monday to all day inputs
        */
        checkRepeat() {
            if (this.values.repeat_schedule == true) {
                this.values.tuesday_schedule_start =
                    this.values.monday_schedule_start;
                this.values.wednesday_schedule_start =
                    this.values.monday_schedule_start;
                this.values.thursday_schedule_start =
                    this.values.monday_schedule_start;
                this.values.friday_schedule_start =
                    this.values.monday_schedule_start;

                this.values.tuesday_schedule_end =
                    this.values.monday_schedule_end;
                this.values.wednesday_schedule_end =
                    this.values.monday_schedule_end;
                this.values.thursday_schedule_end =
                    this.values.monday_schedule_end;
                this.values.friday_schedule_end =
                    this.values.monday_schedule_end;
            }
        },

        /**
            Get departments from api
        */
        getDepartments()
        {
            this.loadingDepartments = true;
            this.$api.departments.getSelect()
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.departments = resp.data.map(item => {
                        return {text: item.name, value: item.id }
                    });
                })
                .finally(() => {
                    this.loadingDepartments = false;
                })
            ;
        },

        /**
            Get countries from api
        */
        getCountries() {
            this.$locationsApi.locations.getCountries().then((resp) => {
                this.serverItems.nationalities = resp.data;
                //console.log(resp.data);
            });
        },

        /**
            Get companies from api
        */
        getCompanies() {
            this.loadingCompanies = true;
            this.$api.user
                .getUserBusinessSelect()
                .then((resp) => {
                    this.serverItems.companies = resp.data.map((item) => {
                        return { text: item.name, value: item.id };
                    });
                })
                .catch((error) => {
                    console.log(error, "error desde form employees");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                });
        },

        /**
         * Get locations
         */
        getLocations(location = undefined) {
            if (location == "" || location == undefined) return;
            this.loadingLocations = true;
            this.$api.locations
                .getLocationsByBusiness(location)
                .then((resp) => {
                    //console.log(resp, "carga de locaciones", location);
                    this.serverItems.locations = resp.data.map((itm) => {
                        return { value: itm.id, text: itm.name };
                    });
                })
                .catch((error) => {
                    console.log(error, "error desde form employees");
                })
                .finally(() => {
                    this.loadingLocations = false;
                });
        },

        /**
         * Get positions
         */
        getPositions(location = undefined) {
            if (location == "" || location == undefined) return;
            //console.log('comienza getPositions', location);
            this.loadingPositions = true;
            this.$api.positions
                .getSelectByBussiness(location)
                .then((resp) => {
                    //console.log(resp, "carga positions", location);
                    this.serverItems.positions = resp.data.map((itm) => {
                        return { value: itm.id, text: itm.name };
                    });
                })
                .catch((error) => {
                    console.log(error, "error desde form employees");
                })
                .finally(() => {
                    this.loadingPositions = false;
                });
        },

        removeFile(index) {
            let deleted = JSON.parse(
                JSON.stringify(this.values.contract_multifiles[index])
            );
            this.values.contract_multifiles.splice(index, 1);
            this.values.delete_contract_files.push({ file_path: deleted.path });
        },
    },
};
</script>

<style lang="scss">
.tab {
    &-header {
        font-size: 12px !important;
        color: var(--v-normalText-lighten4) !important;
    }

    &-active {
        color: var(--v-normalText-base) !important;
    }

    &-error {
        color: var(--v-warning-base) !important;
    }
}
.less-margin {
    margin-bottom: -20px;
}
</style>