<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">
                            Editar Puesto
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="6">
                        <v-form ref="form" @submit.prevent="save">
                            <positions-form ref="PositionsForm" :values="form" @save="save" :serverErrors="serverErrors"></positions-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../../helpers/Utils';
import PrimaryButton from '../../../../components/primaryButton.vue';
import PositionsForm from "./Form.vue";
export default {
    components: {
        'positions-form': PositionsForm,
        PrimaryButton,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            serverErrors: {},
            form: {
                code: '',
                name: '',
                company_id: '',
                // area_id: '',
                // department_id: '',

                requires_travel: false,
                inCharge: false,
                requires_english: false,
                area_manager:false,
                rh_manager:false,

                english_level: '',
                purpose: '',

                report_to_id: '',
                supervise_to_id: '',
                studies: '',
                experience: '',

                background: '',
                abilities: '',
                responsibilities: '',
                authority: '',

                emission_date: '', //emision
                revision: '',
                made_by_id: '', //elaboro
                minimum_age: '', //Edad minima
                approved_by_id: '', //aprobo
                format_key: '', //clave del formato
                format_revision: '', //revision del formato

             
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Positions",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar cambios",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index()
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
			return this.$refs.form.validate();
		},
        index(){
            //this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.positions.get(this.id)
			.then((response) => {
                this.form = response.data;
                this.form = {
                    ...this.form, 
                    // area_id: (response.data.area.id),
                    company_id: (response.data.company_id),
                    report_to_id: (response.data.report_to?.id)??'',
                    supervise_to_id: (response.data.supervise_to?.id)??'',
                    // department_id: (response.data.department.id)??'',
                    made_by_id: (response.data.made_by?.id)??'',
                    approved_by_id: (response.data.approved_by?.id)??'',
                    minimum_age: response.data.minimum_age <= 0 ? '' : (response.data.minimum_age??'')
                }
               
				//console.log(response);
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
        },
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;


            if(this.validate()){             
                const request = this.prepareRequest(this.form);
                console.log(JSON.stringify(request));
                this.$api.positions.update(this.id,request)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'Positions'});
                    })
                    .catch((error) => {
                        console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            // eslint-disable-next-line no-console
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
        },

        prepareRequest(data)
        {
            let newData = JSON.parse(JSON.stringify(data));
            if(newData.report_to_id == '')
                newData.report_to_id = null;
            if(newData.supervise_to_id == '')
                newData.supervise_to_id = null;

            if(data.minimum_age != null || data.minimum_age != "")
                newData.minimum_age = parseInt(data.minimum_age);

            delete newData.report_to;
            delete newData.supervise_to; 
            delete newData.area;
            return newData;
        }
    },
}
</script>

<style>

</style>