<template>
    <div class="resetPassword-container">
        <div class="resetPassword-form">

            <v-form ref="form" @submit.prevent="save">
                <v-row justify="center" >
                    <v-col class="text-center" >
                        <div class="font-weight-bold body-2">
                            Restablece tu contraseña
                        </div>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="py-2" cols="12" sm="9">
                        <div class="resetPassword-message">
                            Cuida tu información y recuerda tomar en cuenta
                            las siguientes recomendaciones para crear tu 
                            nueva contraseña
                        </div>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="py-2" cols="12" sm="9">
                        <div class="resetPassword-rules">
                            <span class="resetPassword-dot">●</span> <span :class="`resetPassword-text-instruction ${form.password == '' ? '' : checkMayus ? 'resetPassword-green' : 'resetPassword-error'}`">Utiliza al menos una letra mayúscula</span> <br>
                            <span class="resetPassword-dot">●</span> <span :class="`resetPassword-text-instruction ${form.password == '' ? '' : checkMinus ? 'resetPassword-green' : 'resetPassword-error'}`">Utiliza al menos una letra minúscula</span><br>
                            <span class="resetPassword-dot">●</span> <span :class="`resetPassword-text-instruction ${form.password == '' ? '' : checkNumber ? 'resetPassword-green' : 'resetPassword-error'}`">Utiliza al menos un número</span><br>
                            <span class="resetPassword-dot">●</span> <span :class="`resetPassword-text-instruction ${form.password == '' ? '' : check8 ? 'resetPassword-green' : 'resetPassword-error'}`">Utiliza al menos 8 caractéres</span><br>
                        </div>
                    </v-col>
                </v-row>

                <v-row justify="center" v-if="errorMessage != ''">
                    <v-col class="py-0 text-center" cols="12" sm="9">
                        <p class="caption red--text mb-0">{{errorMessage}}</p>
                    </v-col>
                </v-row>

               <v-row  justify="center">
                    <v-col class="py-2" cols="12" sm="9">
                        <textFieldForm 
                            @valueChange="(v) => {form.password = v.trim(); delete serverErrors.password}" 
                            :valueInput="form.password" 
                            :rule="[...rules.required, ...rules.atLeastMayus, ...rules.atLeastMinus,...rules.atLeastNumber, ...rules.atLeast8Chars]" 
                            label="Nueva Contraseña"
                            :errorMessages="serverErrors.password"
                            :count="true"
                            :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass ? 'text' : 'password'"
                            @clickAppend="() => {showPass = !showPass}"
                            class="initial-transform-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col class="py-2" cols="12" sm="9">
                        <textFieldForm 
                            @valueChange="(v) => {form.password_confirmation = v.trim(); delete serverErrors.password_confirmation}" 
                            :valueInput="form.password_confirmation" 
                            :rule="[...rules.required, ...rules.samePassword]" 
                            label="Confirmar contraseña"
                            :errorMessages="serverErrors.password_confirmation"
                            :count="true"
                            :type="showPass ? 'text' : 'password'"
                            @click:append="showPass = !showPass"
                            class="initial-transform-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" sm="9"  class="">
                        <!-- <v-row justify="end" class="px-3 py-3"> -->
                            <primary-button :props="saveBtnData" class=""></primary-button>  
                        <!-- </v-row> -->
                    </v-col>
                </v-row>

                 

            </v-form>
            

        </div>
         <img :src="companyLogo" alt="" class="resetPassword-company-logo">

    </div>
</template>

<script>
import Utils from '../../helpers/Utils';
import companyLogo from '../../assets/LOGO_GRUPO_MERCA.png'; 

export default {
    components: {
    },
	data(){
		return {
            errorMessage: "",
            companyLogo,
            token: this.$route.params.token,
            loading: false,
            showPass: false,
            serverErrors: {},
            checkMayus: false,
            checkMinus: false,
            checkNumber: false,
            check8: false,
            form: {
                password: '',
                password_confirmation: ''
            },
            
            saveBtnData: {
                text: "Restablecer contraseña",
                block: true,
                icon: "",	
                click: ()=>{this.save()},
                loading: false,
            },

            rules: {
				required: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                samePassword:[
                    (v) => v == this.form.password || "Las contraseñas no coinciden",
                ],
                atLeast8Chars:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length >= 8) {
                            this.check8 = true;
                            return true
                        }
                        this.check8 = false;
                        return "Al menos 8 caractéres"
                    }
                ],
                atLeastNumber:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(.*\d.*)$/;
                            this.checkNumber = pattern.test(v);
                            return pattern.test(v) || 'Al menos un número';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
                atLeastMinus:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(.*[a-z].*)$/;
                            this.checkMinus = pattern.test(v);
                            return pattern.test(v) || 'Al menos una letra minúscula';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
                atLeastMayus:[
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(.*[A-Z].*)$/;
                            this.checkMayus = pattern.test(v);
                            return pattern.test(v) || 'Al menos una letra mayúscula';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
                
				
				
            },
            
		}
    },
    mounted(){
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
    },
	methods: {
        ToLogin(){
            this.$router.push({name: 'Login'});
        },
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate()
		},
        save(){
            this.errorMessage = "";
            this.saveBtnData.loading = true;
            console.log(this.form, this.validate());
            
            if(this.validate()){
                
                let form = JSON.parse(JSON.stringify(this.form))
                
                this.$api.auth.resetPassword(form, this.token)
                    .then(() => {
                        //console.log(response)
                        this.$router.push({name: 'ResetPasswordSuccess'});
                    })
                    .catch(() => {
                        //console.log(error.response)
                        this.externalRequest();
                       
                    })
                    .finally(() => {
                        // this.saveBtnData.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                // this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        externalRequest(){
            this.saveBtnData.loading = true;
            
            if(this.validate()){
                
                let form = JSON.parse(JSON.stringify(this.form))
                
                this.$api.auth.externalResetPassword(form, this.token)
                    .then(() => {
                        //console.log(response)
                        this.errorMessage = "";
                        this.$router.push({name: 'ResetPasswordSuccess'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        if(x == "message"){
                                            this.errorMessage = (Utils.deleteWord(x,error.response.data.message[x]))
                                        }
                                    });
                                    // this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                // this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        }


    },
}
</script>

<style lang="scss">

    
    .resetPassword{

        &-company-logo{
            height: 3rem;
            margin-top: 16px;
        }

        &-text-instruction{
            font-size: .8rem;
        }

        &-green{
            color: green !important;
        }

        &-error{
            // color: red !important;
        }
        
        
        &-container{
            z-index: 1000;
            display: flex;
            flex-direction: column;
            justify-items: center;
            justify-content: center;
            align-items: center;
            
        }

        &-dot{
            color: var(--v-primary-base);
        }

        &-message{
            line-height: 1.2rem;
            // background-color: red;
            
        }

        &-form{
            
            display: flex;
            flex-direction: column;
            flex-direction: column;
            justify-items: center;
            justify-content: center;
            align-items: center;

            background-color: white;
            min-height: 280px;
            width: 50%;
            padding: 2rem 1rem;

            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
               width: 80%;
                
            }

            @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
               width: 95%;
                
            }

        }

        &-return-btn{
            font-size: .7rem;
            text-align: center;
            margin-top: 2rem;
        }
    }
</style>